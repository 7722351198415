var Renderer = (function() {

	var canvas = null,
		ctx = null,

		colorPreviews = null,
		colorPreviewType = ColorPreviewTypes.UNIFIED,

		materials = {
			left: null,
			right: null
		};


	function init() {
		canvas = new fabric.Canvas('veneer_canvas'),
		ctx = canvas.getContext();

		initColorPreviews();

		CanvasUtility.setCanvasDimensions(canvas);
		canvas.selection = false;
		canvas.renderOnAddRemove = false;
	}

	function initColorPreviews() {
		colorPreviews = {
			left: new colorPreview({
				side: 'left',
				color: Settings.colorPreviewColorLeft,
				containerId: '#colorPreviewLeft',
				otherContainerId: '#colorPreviewRight',
				pickerId: '#colorpicker_left',
				otherPickerId: '#colorpicker_right',
				materialPreviewId: '#material_preview_left',
				otherMaterialPreviewId: '#material_preview_right',
				isSecondaryPreview: false,
				canvas: canvas
			}),
			right: new colorPreview({
				side: 'right',
				color: Settings.colorPreviewColorRight,
				containerId: '#colorPreviewRight',
				otherContainerId: '#colorPreviewLeft',
				pickerId: '#colorpicker_right',
				otherPickerId: '#colorpicker_left',
				materialPreviewId: '#material_preview_right',
				otherMaterialPreviewId: '#material_preview_left',
				isSecondaryPreview: true,
				canvas: canvas
			})
		};
	}

	/**
	 * render the canvas with the current configuration
	 */
	function render() {
		canvas.clear();

		renderColorPreviews();

		var veneers = Scene.getVeneers();
		for (var i = 0, l = veneers.length; i < l; i++) {
			renderVeneer(veneers[i]);
		}

		VeneerListModule.updateVeneerList(true);
		canvas.renderAll();

		if(Scene.getCurrentVeneer()) {
			canvas.setActiveObject(Scene.getCurrentVeneer().group);
		}
	}

	function renderVeneer(veneer) {
		var config = ConfigurationUtility.getConfig(veneer);

		veneer.setMaxImageDimensions();
		// set images that will be used later
		veneer.setImages(config);
		// resize or crop the images if needed
		if(veneer.setImagesDimensions(config)) {
			// set the image positions and combine all images into a single group
			veneer.group = veneer.setImagePositions(config);
			if(veneer.group !== false) {
				// center in canvas
				if (!veneer.centered && veneer.modifiers.center) {
					veneer.center();
				}

				veneer.setPosition();

				// add objects to canvas
				canvas.add(veneer.group);

				if(veneer.isOutsideBounds()) {
					veneer.center();
				}

				//this.renderEffect(veneer);
				//this.renderIntarsias(veneer);

				//this.updateImagedata();
			}
		}
	}

	function renderColorPreviews() {
		var width,
			height,
			lPos = {left: 0, top: 0},
			rPos = {left: 0, top: 0};

		$('.sg_tool_colorpreview_color').toggleClass('horizontal', (colorPreviewType === ColorPreviewTypes.HORIZONTAL));
		$('.sg_tool_colorpreview_color').toggleClass('vertical', (colorPreviewType === ColorPreviewTypes.VERTICAL));

		if (colorPreviewType === ColorPreviewTypes.HORIZONTAL) {
			width = canvas.width;
			height = canvas.height / 2;

			rPos.top = height;
		}
		if (colorPreviewType === ColorPreviewTypes.VERTICAL) {
			width = canvas.width / 2;
			height = canvas.height;

			rPos.left = width;
		}

		if (colorPreviewType === ColorPreviewTypes.UNIFIED) {
			width = canvas.width;
			height = canvas.height;

			colorPreviews.left.render(lPos, width, height);

			colorPreviews.right.picker.css('border-color', colorPreviews.left.color);
		} else {
			colorPreviews.left.render(lPos, width, height);
			colorPreviews.right.render(rPos, width, height);

			colorPreviews.right.picker.css('border-color', colorPreviews.right.color);

			if (materials.right !== null) {
				colorPreviews.right.setPattern(materials.right.getElement());
			} else {
				colorPreviews.right.removePattern();
			}
		}

		if (materials.left !== null) {
			colorPreviews.left.setPattern(materials.left.getElement());
		} else {
			colorPreviews.left.removePattern(true);
		}
	}

	function getCanvasDimensions() {
		return {
			width: canvas.width,
			height: canvas.height
		}
	}

	function getColorPreviewType() {
		return colorPreviewType;
	}

	function setColorPreviewType(type) {
		colorPreviewType = type;
	}

	function setMaterial(side, image) {
		materials[side] = image;
	}

	function getCanvas() {
		return canvas;
	}

	return {
		init: init,
		render: render,
		getCanvasDimensions: getCanvasDimensions,
		getColorPreviewType: getColorPreviewType,
		setColorPreviewType: setColorPreviewType,
		setMaterial: setMaterial,
		getCanvas: getCanvas
	}

}());