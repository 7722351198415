/**
 * Data driven jointing configurations
 * Top Index corresponds to ImageStates enums
 * Any further indexes correspond to a veneer
 * increasing the indexes adds more veneers to the configuration
 * 
 * COMMANDS:
 * 
 * Move image in x direction
 * moveX: {direction: 'left'|'right', by: 'width'|'height', image: [imgIndex], multiplier: [Number]}
 * moveX: 'left'|'right' => moveX: {direction: 'left'|'right', by: 'width', image: 0, multiplier: 1}
 * moveX: [ARRAY OF ARBITARY MOVE COMMANDS] -> allows chaining move commands on a single image (avoid if possible to keep configurations simple)
 * 
 * Move image in y direction
 * moveY: {direction: 'top'|'bottom', by: 'width'|'height', image: [imgIndex], multiplier: [Number]}
 * moveY: 'top'|'bottom' => moveX: {direction: 'top'|'bottom', by: 'height', image: 0, multiplier: 1}
 * moveY: [ARRAY OF ARBITARY MOVE COMMANDS]
 * 
 * IMAGE ALWAYS MOVES TO THE RIGHT OF THE PREVIOUS IMAGE IF NO OTHER MOVE COMMAND IS GIVEN! (moveX: 'right')
 * 
 * scale the image
 * scale: [factor]
 * 
 * apply image modifiers
 * modifiers: [
 *	{modifier: [modifierName], value: [modifiervalue]}
 * ]
 * modifiers: Anything that can be set by fabric.Image.set(key, value), see: http://fabricjs.com/docs/fabric.Image.html#set
 * 
 * Cropping
 * crop: {left: [0-1], top: [0-1], width: [0-1], height: [0-1]}
 * Crops an image based on the given percentages
 * left, top : point to start the cropping at
 * width, height : dimensions of the new image
 * 
 * CONFIGURATION:
 * 
 * veneersPerColumn: [number]
 * Amount of veneers in a column (default 1)
 * Used for calculating the width of the configuration
 * 
 * requireCut: 'string'
 * Set if the configuration requires a specific shape to be cut beforehand
 * Possible values:
 *	square
 *	diamond
 *	horizontal_triangle
 *	veritcal_triangle
 *	right_triangle
 *	
 * resetPositionX: {
 *	[any move commands]
 * }
 * Allows moving the pointer after all veneers have been set to make sure any following veneers align correctly
 * Useful when the pointer is not to the right of the finished group
 * 
 * extract: {
 *	shape: ['square'],
 *	height: [number],
 *	width: [number]
 * }
 * Extracts a shape from the finished configuration and uses that shape for rendering instead
 * Available shapes: square
 * height and width are expected to be > 1
 * 
 * extractConfig: {
 *	0: {
 *		[Any commands]
 *	}
 * }
 * Configuration array to be used with extracted shapes
 * 
 * rotate: {angle: [degrees], direction: 'top'|'left', image: [number], dimension: 'width'|'height', multiplier: [number]},
 * rotate the completed image set and move the set afterwards
 */
var JointingConfigurations = {
	// Single
	0: {
		0: {},
	},
	// Mirrored left
	1: {
		0: {},
		1: {
			modifiers: [
				{modifier: 'flipX', value: true}
			]
		},
	},
	// Mirrored right
	2: {
		0: {
			modifiers: [
				{modifier: 'flipX', value: true}
			]
		},
		1: {},
	},
	// Pushed
	3: {
		0: {},
		1: {},
	},
	// Cross joint
	4: {
		0: {
			scale: 0.5,
		},
		1: {
			scale: 0.5,
			modifiers: [
				{modifier: 'flipX', value: true}
			]
		},
		2: {
			scale: 0.5,
			moveY: 'bottom',
			moveX: 'left',
			modifiers: [
				{modifier: 'flipY', value: true},
			]
		},
		3: {
			scale: 0.5,
			modifiers: [
				{modifier: 'flipY', value: true},
				{modifier: 'flipX', value: true}
			]
		},
		veneersPerColumn: 2
	},
	// Herringbone
	5: {
		0: {
			scale: 0.5
		},
		1: {
			rotate: 90,
			moveX: [{direction: 'right', by: 'height'}, 'right'],
			moveY: ['bottom', {direction: 'top', by: 'width'}],
			scale: 0.5
		},
		2: {
			moveX: [{direction: 'left', by: 'height'}, 'left'],
			moveY: [{direction: 'bottom', by: 'width'}, 'top'],
			rotate: 90,
			scale: 0.5
		},
		3: {
			moveX: {direction: 'left', by: 'height'},
			moveY: 'top',
			scale: 0.5
		},
		// @todo how did this work again?
		rotate: {
			angle: 45, 
			image: 0, 
			dimensionY: 'width', multiplierY: 1 - Math.sin(fabric.util.degreesToRadians(45)), 
			dimensionX: 'width', multiplierX: Math.sin(fabric.util.degreesToRadians(45))
		},
		resetPositionX: {
			moveX: {direction: 'right', by: 'height'},
			moveY: ['bottom', {direction: 'top', by: 'width'}]
		},
		veneersPerColumn: 4
	},
	// Diamond
	6: {
		0: {},
		1: {
			moveY: 'bottom',
			modifiers: [
				{modifier: 'flipY', value: true},
			]
		},
		2: {
			moveX: 'right',
			moveY: 'top',
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		3: {
			moveY: 'bottom',
			modifiers: [
				{modifier: 'flipX', value: true},
				{modifier: 'flipY', value: true},
			]
		},
		requireCut: 'diamond',
		veneersPerColumn: 2,
	},
	// reverse Diamond
	7: {
		0: {
			modifiers: [
				{modifier: 'flipY', value: true},
			]
		},
		1: {
			moveY: 'bottom',
		},
		2: {
			moveX: 'right',
			moveY: 'top',
			modifiers: [
				{modifier: 'flipY', value: true},
				{modifier: 'flipX', value: true},
			]
		},
		3: {
			moveY: 'bottom',
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		requireCut: 'diamond',
		veneersPerColumn: 2
	},
	// cross joint diagonal upright
	8: {
		0: {
			modifiers: [
				{modifier: 'flipY', value: true},
			]
		},
		1: {
			moveX: 'left',
			moveY: {direction: 'bottom', by: 'width'},
			rotate: -90
		},
		2: {
			moveX: {direction: 'right', by: 'height'},
			moveY: {direction: 'bottom', by: 'width', image: 2},
			rotate: -180,
			modifiers: [
				{modifier: 'flipY', value: true},
			]
		},
		3: {
			moveX: 'right',
			moveY: {direction: 'top', by: 'height', image: 3},
			rotate: -270
		},
		// reset the pointer to the correct position for the next loop
		// only necessary if the pointer is not already to the right of the previous group
		resetPositionX: {
			moveX: {direction: 'left', by: 'width'},
			moveY: {direction: 'top', by: 'width'},
		},
		resetPositionY: {
			moveX: 0,
			moveY: {direction: 'bottom', by: 'height', multiplier: 0.5}
		},
		requireCut: 'vertical_triangle',
		veneersPerColumn: 2
	},
	// cross joint diagonal across
	9: {
		0: {
			modifiers: [
				{modifier: 'flipY', value: true},
			]
		},
		1: {
			moveY: 'bottom',
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		2: {
			moveX: {direction: 'right', by: 'height'},
			moveY: 'top',
			rotate: 90
		},
		3: {
			moveY: 'bottom',
			rotate: -90
		},
		resetPositionY: {
			moveX: 0,
			moveY: 'none'
		},
		requireCut: 'horizontal_triangle',
		veneersPerColumn: 4
	},
	// parquet
	10: {
		0: {
			moveY: {direction: 'top', by: 'height', multiplier: {min: 0, max: 1, minDifference: 0.3}}
		},
		1: {
			moveY: {direction: 'bottom', by: 'height', multiplier: 1}
		},
		2: {
			moveY: {direction: 'bottom', by: 'height', multiplier: 1}
		},
		3: {
			moveY: {direction: 'bottom', by: 'height', multiplier: 1}
		},
		resetPositionX: {
			moveY: 0,
			moveX: 'right',
		},
		onRotate: {direction: 'left', by: 'height'},
		veneersPerColumn: 4,
	},
	// english parquet
	11: {
		0: {
			moveY: {direction: 'top', by: 'height', multiplier: [0, 0.5]}
		},
		1: {
			moveY: {direction: 'bottom', by: 'height', multiplier: 1}
		},
		2: {
			moveY: {direction: 'bottom', by: 'height', multiplier: 1}
		},
		3: {
			moveY: {direction: 'bottom', by: 'height', multiplier: 1}
		},
		resetPositionX: {
			moveY: 0,
			moveX: 'right',
		},
		resetPositionY: {

		},
		// @todo comment on why this is necessary
		onRotate: {direction: 'left', by: 'height'},
		veneersPerColumn: 4,
	},
	// starburst
	12: {
		0: {
			moveX: {direction: 'right', by: 'width', multiplier: 2},
			moveY: 'bottom'
		},
		1: {
			moveX: {direction: 'right', by: 'width', image: 1},
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		2: {
			moveX: {direction: 'left', by: 'width', image: 2, multiplier: 0.5},
			moveY: {direction: 'bottom', by: 'width', image: 2, multiplier: 0.5},
			rotate: -45,
		},
		3: {
			moveX: {direction: 'right', by: 'width', image: 3, multiplier: 0.5},
			moveY: {direction: 'top',  by: 'width', image: 3, multiplier: 0.5},
			rotate: -45,
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		4: {
			moveY: {direction: 'bottom', by: 'height', image: 4},
			rotate: -90
		},
		5: {
			moveY: {direction: 'top', by: 'height', image: 5},
			rotate: -90,
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		6: {
			moveX: {direction: 'right', by: 'width', image: 6, multiplier: 0.5},
			moveY: {direction: 'bottom', by: 'width', image: 6, multiplier: 0.5},
			rotate: -135
		},
		7: {
			moveX: {direction: 'left', by: 'width', image: 7, multiplier: 0.5},
			moveY: {direction: 'top', by: 'width', image: 7, multiplier: 0.5},
			rotate: -135,
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		8: {
			moveX: {direction: 'right', by: 'width', image: 8},
			rotate: -180
		},
		9: {
			moveX: {direction: 'left', by: 'width', image: 9},
			modifiers: [
				{modifier: 'flipX', value: true},
			],
			rotate: -180
		},
		10: {
			moveX: {direction: 'right', by: 'width', image: 10, multiplier: 0.5},
			moveY: {direction: 'top', by: 'width', image: 10, multiplier: 0.5},
			rotate: -225
		},
		11: {
			moveX: {direction: 'left', by: 'width', image: 11, multiplier: 0.5},
			moveY: {direction: 'bottom', by: 'width', image: 11, multiplier: 0.5},
			rotate: -225,
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		12: {
			moveY: {direction: 'top', by: 'height', image: 12},
			rotate: -270
		},
		13: {
			moveY: {direction: 'bottom', by: 'height', image: 13},
			rotate: -270,
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		14: {
			moveX: {direction: 'left', by: 'width', image: 14, multiplier: 0.5},
			moveY: {direction: 'top', by: 'width', image: 14, multiplier: 0.5},
			rotate: -315
		},
		15: {
			moveX: {direction: 'right', by: 'width', image: 14, multiplier: 0.5},
			moveY: {direction: 'bottom', by: 'width', image: 15, multiplier: 0.5},
			rotate: -315,
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		requireCut: 'right_triangle',
		// this is pretty dirty; consider a better solution
		// prevents starburst from being scaled-down unnecessarily
		veneersPerColumn: 6,
		extract: {
			shape: 'square',
			// ~ sin(45)
			height: 0.7,
			width: 0.7
		},
		extractConfig: {
			0: {}
		}
	},
	// Web 1
	13: {
		0: {
			crop: {left: 0, top: 0, width: 1/2, format: 2}
		},
		1: {
			crop: {left: 0, top: 1/2, width: 1/2, format: 2}
		},
		2: {
			crop: {left: 0, top: 0, width: 1, format: 1}
		},
		3: {
			useImage: 1
		},
		4: {
			useImage: 0
		},
		5: {
			crop: {left: 0, top: 1/2, width: 1, format: 1}
		},
		6: {
			moveY: 'bottom',
			crop: {left: 0, top: 0, width: 1, format: 1/2}
		},
		7: {
			moveX: {direction: 'left', by: 'width', image: 0},
			crop: {left: 0, top: 1/2, width: 1/2, format: 1}
		},
		8: {
			moveX: {direction: 'left', by: 'width', image: 0},
			crop: {left: 0, top: 0, width: 1/2, format: 1}
		},
		9: {
			moveX: {direction: 'left', by: 'width', image: 5},
			crop: {left: 0, top: 1/2, width: 1, format: 1/2}
		},
		10: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 8
		},
		11: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 7
		},
		12: {
			moveY: 'bottom',
			useImage: 8
		},
		13: {
			useImage: 7
		},
		14: {
			useImage: 6
		},
		15: {
			useImage: 7
		},
		16: {
			useImage: 8
		},
		17: {
			useImage: 9
		},
		18: {
			moveY: 'bottom',
			useImage: 2
		},
		19: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 1
		},
		20: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 0
		},
		21: {
			moveX: {direction: 'left', by: 'width', image: 2},
			useImage: 5
		},
		22: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 0
		},
		23: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 1
		},
		24: {
			moveY: 'bottom',
			useImage: 8
		},
		25: {
			useImage: 7
		},
		26: {
			useImage: 6
		},
		27: {
			useImage: 7
		},
		28: {
			useImage: 8
		},
		29: {
			useImage: 9
		},
		30: {
			moveY: 'bottom',
			useImage: 6
		},
		31: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 7
		},
		32: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 8
		},
		33: {
			moveX: {direction: 'left', by: 'width', image: 5},
			useImage: 9
		},
		34: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 8
		},
		35: {
			moveX: {direction: 'left', by: 'width', image: 0},
			useImage: 7
		},
		veneersPerColumn: 6,
		//forceMixMatch: true,
		extract: {
			shape: 'square',
			height: 1,
			width: 1
		},
		extractConfig: {
			0: {}
		}
	},
	// WEB_2
	14: {
		0: {
			// kleine raute
			cropPolygon: {
				points: [{x: 0, y: 115.92}, {x: 31.06, y: 231.84}, {x: 62.12, y: 115.92}, {x: 31.06, y: 0}],
				offsetX: -60,
				offsetY: 0,
				angle: -15
			}
		},
		1: {
			cropPolygon:{
				points: [{x: 0, y: 115.92}, {x: 62.12, y: 347.76}, {x: 93.18, y: 231.84}, {x: 31.06, y: 0}],
				offsetX: -60,
				offsetY: -20,
				angle: -15
			},
			moveY: {direction: 'top', by: 'height', image: 0, multiplier: 1},
			moveX: {d: 'right', by: 'width', m: 0.5},
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		2: {
			// parallelogram nach links ausgerichtet
			cropPolygon:{
				points: [{x: 0, y: 115.92}, {x: 62.12, y: 347.76}, {x: 93.18, y: 231.84}, {x: 31.06, y: 0}],
				offsetX: -80,
				offsetY: -150,
				angle: -15
			},
			moveY: {direction: 'bottom', by: 'height', image: 0, multiplier: 0.5},
			moveX: {d: 'right', by: 'width', m: 0.5, image: 0},
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		3: {
			useImage: 0,
			moveX: {d: 'right', by: 'width', image: 3},
			moveY: {d: 'top', by: 'height', image: 3, m: 0.5},
		},
		4: {
			useImage: 1
		},
		5: {
			useImage: 2,
			moveX: {d: 'left', by: 'width', m: 0.5, image: 0},
			moveY: {direction: 'bottom', by: 'height', image: 0, multiplier: 0.5},
		},
		6: {
			// große raute
			cropPolygon: {
				points: [{x: 0, y: 231.84}, {x: 62.12, y: 463.68}, {x: 124.24, y: 231.84}, {x: 62.12, y: 0}],
				offsetX: 0,
				offsetY: -20,
				angle: 15
			},
			moveX: {d: 'left', by: 'width', image: 6, m: 0.5},
			moveY: {d: 'bottom', by: 'height', image: 0, m: 0.5},
		},
		7: {
			// kleine raute
			cropPolygon: {
				points: [{x: 0, y: 115.92}, {x: 31.06, y: 231.84}, {x: 62.12, y: 115.92}, {x: 31.06, y: 0}],
				offsetX: 0,
				offsetY: -60,
				angle: 15
			},
			moveX: {direction: 'left', by: 'width', image: 0},
			moveY: {direction: 'bottom', by: 'height', image: 0, multiplier: 0.5},
		},
		8: {
			useImage: 7,
			moveX: {direction: 'left', by: 'width', image: 7},
		},
		9: {
			useImage: 0,
			moveX: {direction: 'right', by: 'width', image: 8, multiplier: 0.5},
			moveY: {direction: 'bottom', by: 'height', image: 8, multiplier: 0.5},
		},
		10: {
			useImage: 1,
			moveX: {direction: 'right', by: 'width', image: 8, multiplier: 1},
		},
		11: {
			useImage: 2,
			moveX: {direction: 'right', by: 'width', image: 10, multiplier: 1},
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		12: {
			cropPolygon:{
				points: [{x: 0, y: 115.92}, {x: 62.12, y: 347.76}, {x: 93.18, y: 231.84}, {x: 31.06, y: 0}],
				offsetX: 0,
				offsetY: -30,
				angle: 15
			},
			moveX: {d: 'right', by: 'width', i: 0, m: 0.5},
			moveY: {d: 'bottom', by: 'height', i: 0, m: 0.5},
			modifiers: [
				{modifier: 'flipX', value: true},
			]
		},
		13: {
			useImage: 11,
			moveX: [{d: 'left', by: 'width', i: 0}, {d: 'left', by: 'width', i: 12, m: 1}],
		},
		14: {
			cropPolygon: {
				points: [{x: 0, y: 231.84}, {x: 62.12, y: 463.68}, {x: 124.24, y: 231.84}, {x: 62.12, y: 0}],
				offsetX: -60,
				offsetY: 0,
				angle: -15
			},
			moveX: {d: 'left', by: 'width', i: 14, m: 0.5},
			moveY: {d: 'bottom', by: 'height', i: 0, m: 0.5},
		},
		15: {
			useImage: 0,
			moveX: 'right',
			moveY: {d: 'bottom', by: 'height', i: 0, m: 0.5},
		},
		16: {
			useImage: 7,
			moveX: {d: 'right', m: 0.5},
			moveY: {d: 'top', m: 0.5},
		},
		17: {
			useImage: 0,
			moveX: {d: 'right', m: 0.5},
			moveY: {d: 'bottom', m: 0.5},
		},
		resetPositionX: {
			moveY: {d: 'top', multiplier: 1.25, image: 14},
			moveX: {d: 'right', multiplier: 0.5}
		},
		resetPositionY: {
			moveX: 0,
			moveY: {direction: 'bottom', by: 'height', multiplier: 1, image: 13}
		},
		veneersPerColumn: 4
	}
};