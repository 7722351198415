var InterfaceEvents = (function() {

	var $toolTitle = null,

		$amountXLabel = null,
		$amountYLabel = null,

		$toolboxSelects = null,
		$toolboxes = null,

		$veneerConfigSelectors = null,

		$materialSelectors = null,

		$colorPreviewTypeSelectors = null;

	function init() {
		$toolTitle = $('#sg_tool_title');

		$amountXLabel = $('#amountX'),
		$amountYLabel = $('#amountY'),

		$toolboxSelects = $('.sg_tool_toolboxselect'),
		$toolboxes = $('.sg_tool_toolbox'),

		$veneerConfigSelectors = $('.veneer_config_change'),

		$materialSelectors = $('.materialList li'),

		$colorPreviewTypeSelectors = $('.colorPreviewType');
	}

	function bindEventHandlers() {
		setAjaxLinksHandler();

		// menu events
		$('#nav_close').on('click', $.proxy(VeneerDesigner.closeDesigner, VeneerDesigner));

		// toolbox events
		$toolboxSelects.on('click', changeActiveToolbox);

		// veneer selection events
		$('#addVeneer').on('click', function(e) {
			VeneerListModule.stopReplacingVeneer();
			VeneerGallery.showGallery(e);
		});
		$('.removeVeneer').on('click', $.proxy(VeneerListModule.removeVeneer, VeneerListModule));

		$('.sg_amount_button').on('click', $.proxy(changeMultiply, this));

		// veneer jointing configuration events
		$veneerConfigSelectors.on('click', changeVeneerJointing);

		$('#rotate').on('click', rotateVeneer);

		$('#downloadImage').on('click', ImageDownloadModule.downloadImage);

		// colorpreview events
		$materialSelectors.on('click', materialChange);

		$colorPreviewTypeSelectors.on('click', colorPreviewTypeChange)

		// canvas events
		Renderer.getCanvas().on('mouse:down', function (ev) {
			ev.e.preventDefault();
			return false;
		});

		Renderer.getCanvas().on('object:moving', function (ev) {
			var gridSize = (ev.e.shiftKey ? Settings.shiftGridSize : Settings.gridSize);

			ev.target.set({
				left: Math.round(ev.target.left / gridSize) * gridSize,
				top: Math.round(ev.target.top / gridSize) * gridSize
			});
		});

		Renderer.getCanvas().on('object:modified', function (ev) {
			var veneer = Scene.getCurrentVeneer();

			veneer.position.left = ev.target.left;
			veneer.position.top = ev.target.top;
			veneer.position.angle = ev.target.angle;
			veneer.position.scaleX = ev.target.scaleX;
			veneer.position.scaleY = ev.target.scaleY;
		});

		Renderer.getCanvas().on('object:rotating', function (ev) {
			ev.target.angle = Math.round(ev.target.angle / Settings.rotationStep) * Settings.rotationStep
		});

		Renderer.getCanvas().on('object:selected', function (ev) {
			var veneer = Scene.setCurrentVeneer(ev.target);
			VeneerListModule.updateVeneerList(true);
			updateInterfaceWithVeneerSettings(veneer);
		});

		// global events
		$(window).on('resize', onResize);

		//$(window).on('keydown', onKeydown);

		/*
		 document.getElementById('form_rectangle').addEventListener('click', cutRectangle.bind(this));
		 document.getElementById('form_circle').addEventListener('click', cutCircle.bind(this));
		 document.getElementById('form_triangle').addEventListener('click', cutTriangle.bind(this));
		 //document.getElementById('form_lasso').addEventListener('click', cutLasso.bind(this));

		 document.getElementById('formcut').addEventListener('click', cut.bind(this));
		 document.getElementById('formcut_reset').addEventListener('click', resetRender.bind(this));
		 */
	}

	function changeMultiply(event) {
		var $target = event.delegateTarget,
			veneer = Scene.getCurrentVeneer(),
			modifier = $target.getAttribute('data-dir'),
			delta = parseInt($target.getAttribute('data-d'));

		veneer.setModifier(modifier, veneer.getModifier(modifier) + delta);

		setAmountLabels(veneer);

		//veneer.clearSpecialImages();
		Renderer.render();
	}

	function updateInterfaceWithVeneerSettings(veneer) {
		setAmountLabels(veneer);
		setJointing(veneer);
	}

	function setAmountLabels(veneer) {
		$amountXLabel.html(veneer.getModifier('multiplyX'));
		$amountYLabel.html(veneer.getModifier('multiplyY'));
	}

	function setJointing(veneer) {
		$veneerConfigSelectors.prop('checked', false);
		$veneerConfigSelectors.filter(function (index, element) {
			return parseInt(element.value) === veneer.getModifier('jointing');
		}).prop('checked', true);
	}

	function changeActiveToolbox(event) {
		var $target = $(event.delegateTarget),
			toolboxId = $target.data('toolbox');

		$toolboxSelects.removeClass('selected');
		$target.addClass('selected');

		$toolboxes.hide();
		$('#' + toolboxId).show();
	}

	function changeVeneerJointing(event) {
		//hideCutTool();

		var $target = $(event.delegateTarget),
		jointing = parseInt($target.val());

		Scene.getCurrentVeneer().setModifier('jointing', jointing);

		/*
		if (newConf.requireCut !== "undefined" || newConf.extract !== "undefined") {
			reset();
		}
		*/

		Renderer.render();
	}

	function rotateVeneer(event) {
		var veneer = Scene.getCurrentVeneer();
		veneer.position.angle += 90;

		//veneer.clearSpecialImages();
		Renderer.render();
	}

	function materialChange(event) {
		var $target = $(event.delegateTarget),
			side = $target.parent().data('side'),
			value = parseInt($target.data('material'));

		$target.siblings().removeClass('act');
		$target.addClass('act');

		if (value > 0) {
			ImageUtility.getMaterialImage(value, side);
		} else {
			Renderer.setMaterial(side, null);
			Renderer.render();
		}
	}

	function effectChange(event) {
		var value = event.target.value;

		if (value !== "0") {
			veneer.getEffect(value);
		} else {
			veneer.effect = null;
			Renderer.render();
		}
	}

	function colorPreviewTypeChange(event) {
		var $target = $(event.delegateTarget);

		$colorPreviewTypeSelectors.removeClass('act');
		$target.addClass('act');

		Renderer.setColorPreviewType($target.data('value'));

		Renderer.render();
	}

	function onResize(event) {
		CanvasUtility.setCanvasDimensions(Renderer.getCanvas());
		Renderer.render();
	}

	function onKeydown(event) {
		var key = event.keyCode,
			activeObj = Renderer.getCanvas().getActiveObject();

		// entf
		/*
		if (key === 46) {
			if (activeObj !== null) {
				veneer.removeIntarsia(activeObj.intarsiaIndex);
				Renderer.render();
			}
		}
		*/
	}

	/**
	 * Updates the veneerDesigner title
	 * @param title
	 */
	function setToolTitle(title) {
		$toolTitle.html(title);
	}

	return {
		init: init,
		bindEventHandlers: bindEventHandlers,
		setAmountLabels: setAmountLabels,
		setToolTitle: setToolTitle
	}
}());