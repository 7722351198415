var EffectModule = (function() {

	function renderEffect(veneer) {
		if (veneer.effect === null) {
			return;
		}

		var width = veneer.group.width,
			coveredWidth = 0,
			height = veneer.group.height,
			//coveredHeight = veneer.effect.getHeight(),
			coveredHeight = 0,
			pos = {left: veneer.group.left, top: veneer.group.top},
			rows = 0;

		if (veneer.modifiers.rotate) {
			pos.top = veneer.group.top - veneer.group.getWidth();
			width = veneer.group.height;
			height = veneer.group.width;
		}

		/*
		 while (coveredWidth + pos.left < pos.left + width) {
		 var ef = new fabric.Image(veneer.effect.getElement());
		 ef.selectable = false;
		 ef.hasBorder = false;
		 ef.setTop(pos.top);
		 ef.setLeft(pos.left + coveredWidth);
		 ef.coveredWidth = coveredWidth;
		 ef.clipTo = function (ctx) {
		 var w = ef.getWidth(),
		 h = ef.getHeight();
		 if (self.veneer.modifiers.rotate) {
		 if (pos.left + w > pos.left + width) {
		 w = pos.left + width - ef.getLeft();
		 }
		 } else {
		 if (h > height) {
		 h = pos.top + height - ef.getTop();
		 }
		 }

		 ctx.rect(-ef.width / 2, -ef.height / 2, w, h);
		 };

		 Renderer.getCanvas().add(ef);
		 coveredWidth += ef.getWidth();
		 rows++;
		 }

		 while (coveredHeight + pos.top < pos.top + height) {
		 for (var i = 0; i < rows; i++) {
		 var ef = new fabric.Image(veneer.effect.getElement());
		 ef.selectable = false;
		 ef.hasBorder = false;
		 ef.setTop(pos.top + coveredHeight);
		 ef.setLeft(pos.left + (ef.getWidth()) * i);
		 ef.coveredWidth = coveredWidth;
		 ef.clipTo = function (ctx) {
		 var w = ef.getWidth(),
		 h = ef.getHeight();
		 if (self.veneer.modifiers.rotate) {
		 if (coveredWidth + w > width) {
		 w = pos.left + width - ef.getLeft();
		 }
		 } else {
		 if (h > height) {
		 h = pos.top + height - ef.getTop();
		 }
		 }
		 ctx.rect(-ef.width / 2, -ef.height / 2, w, h);
		 };

		 Renderer.getCanvas().add(ef);
		 }
		 coveredHeight += ef.getHeight();
		 }
		 */
	}

	return {

	}
}());