var VeneerListModule = (function() {

	var $veneerList = null,
		$currentVeneerDisplays = null,
		veneerToReplace = null;

	function init() {
		$veneerList = $('#sg_veneerList');
		$currentVeneerDisplays = $('.sg_currentVeneerDisplay');
	}

	function addVeneer(identifier) {
		if(typeof identifier === "object") {
			identifier = $(identifier.delegateTarget).data('uid');
		}

		var veneer = null;

		if(veneerToReplace === null) {
			veneer = Scene.add(new Veneer(identifier));
		} else {
			veneer = veneerToReplace;
			veneer.uid = identifier;
			ImageUtility.loadImages(veneer);
		}

		stopReplacingVeneer();

		utilities.getVeneerData(identifier, function (response) {
			var veneerData = utilities.parseJson(response);
			veneer.resizedImage = veneerData.resizedImage;
			updateVeneerList(true);
		}, 70, 70);
	}

	function removeVeneer(e) {
		var target = $(e.delegateTarget),
			stackIndex = target.parent().data('index');

		var removedVeneer = Scene.remove(stackIndex);
		if (removedVeneer !== undefined) {
			Renderer.getCanvas().remove(removedVeneer.group);
		}
		if(stackIndex === Scene.getCurrentVeneerIndex() && Scene.getVeneers().length >= 1) {
			Scene.setCurrentVeneerByIndex(Scene.getVeneers().length - 1);
		}
		if(Scene.getVeneers().length === 0) {
			InterfaceEvents.setToolTitle('&nbsp;');
		}

		removeFromVeneerList(target);

		Renderer.render();
	}

	function centerVeneer(e) {
		var target = $(e.delegateTarget),
			veneer = Scene.getVeneer(target.parent().data('index'));

		// recenter on next render
		veneer.centered = false;

		Renderer.render();
	}

	function replaceVeneer(e) {
		var target = $(e.delegateTarget);
		veneerToReplace = Scene.getVeneer(target.parents('li').data('index'));

		VeneerGallery.showGallery(e);
	}

	function stopReplacingVeneer() {
		veneerToReplace = null;
	}

	function addToVeneerList(data, index) {
		$veneerList.prepend(createVeneerListPoint(data, index));

		updateVeneerList();
	}

	function createVeneerListPoint(data, index) {
		var li = document.createElement('li'),
			button = document.createElement('button'),
			remove = document.createElement('span'),
			center = document.createElement('span');

		button.setAttribute('class', 'veneerButton');
		button.innerHTML = data.name;

		remove.setAttribute('class', 'removeVeneer');

		center.setAttribute('class', 'centerVeneer');

		li.innerHTML = data.resizedImage;
		li.appendChild(button);
		li.appendChild(remove);
		li.appendChild(center);

		var $li = $(li);

		$li.find('img').on('click', replaceVeneer);
		$li.data('index', index);
		$li.on('click', function(e) {
			Scene.setCurrentVeneerByIndex($(e.delegateTarget).data('index'));
			Renderer.getCanvas().setActiveObject(Scene.getCurrentVeneer().group);
			updateVeneerList(true);
		});

		$(remove).on('click', removeVeneer);
		$(center).on('click', centerVeneer);

		return li;
	}

	function removeFromVeneerList(target) {
		var listElement = target.parent();
		listElement.remove();

		updateVeneerList();
	}

	function updateVeneerList(reRenderList) {
		if(reRenderList === true) {
			clearVeneerList();
			var veneers = Scene.getVeneers();
			for(var i = 0, l = veneers.length; i < l; i++) {
				if(i === l-1) {
					$currentVeneerDisplays.html(createVeneerListPoint(veneers[i], i));
				}

				addToVeneerList(veneers[i], i);
			}
		} else {
			var listElements = $veneerList.find('li').not('#addVeneer');

			listElements.each(function (index, element) {
				$(element).data('index', listElements.length - index - 1);
			});
		}
	}

	function clearVeneerList() {
		$veneerList.find('li').not('#addVeneer').remove();
		$currentVeneerDisplays.html('');
	}

	return {
		init: init,
		addVeneer: addVeneer,
		removeVeneer: removeVeneer,
		updateVeneerList: updateVeneerList,
		stopReplacingVeneer: stopReplacingVeneer
	}
}());