/**
 * Settings object
 * @type object
 */
var Settings = {
	canvasBottomMargin: 30,
	canvasMaxHeight: 9999,
	trimTolerance: 180,
	outOfBoundsTolerance: 10,
	colorPreviewMinSize: 100,
	colorPreviewColorLeft: '#323232',
	colorPreviewColorRight: '#BABABA',
	gridSize: 1,
	shiftGridSize: 10,
	rotationStep: 3,

	/**
	 * Styles for the selection box on canvas elements
	 * @type {object}
	 */
	SelectionBoxStyles: {
		borderColor: 'rgba(255, 255, 255, 0.8)',
		borderDashArray: [5, 5],
		cornerColor: 'rgba(255, 255, 255, 0.8)',
		cornerStrokeColor: 'rgba(0, 0, 0, 0.8)',
		cornerSize: 18,
		cornerStyle: 'circle',
		transparentCorners: false
	}
};

/**
 * Image States enums
 * @type object
 */
var ImageStates = {
	SINGLE: 0,
	MIRRORED_LEFT: 1,
	MIRRORED_RIGHT: 2,
	PUSHED: 3,
	CROSS_JOINT: 4,
	HERRINGBONE: 5,
	DIAMOND: 6,
	REVERSE_DIAMOND: 7,
	CROSS_JOINT_DIAGONAL_UPRIGHT: 8,
	CROSS_JOINT_DIAGONAL_ACROSS: 9,
	PARQUET_WILD: 10,
	PARQUET_ENGLISH: 11,
	STARBURST: 12,
	WEB_1: 13,
	WEB_2: 14
};

/**
 * Color Preview Type enums
 * @type object
 */
var ColorPreviewTypes = {
	UNIFIED: 0,
	HORIZONTAL: 1,
	VERTICAL: 2
};
