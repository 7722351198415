function Veneer(uid) {

	this.uid = uid;
	this.name = '';
	this.imageCount = 0;
	// small preview image
	this.resizedImage = null;

	this.position = {
		left: 0,
		top: 0,
		angle: 0,
		scaleX: 1,
		scaleY: 1
	};

	this.centered = false;

	this.originalImages = [];

	// fabric images
	this.images = [];

	this.cutImages = [];

	this.modifiers = {
		jointing: ImageStates.SINGLE,
		multiplyX: 1,
		multiplyY: 1,
		fit: false,
		mixmatch: false,
		images: 1,
		center: true,
		colorVeneer: false,
		veneerColor: 'transparent'
	};
	this.modifierLimits = {
		multiplyX: {
			min: 1,
			max: 16
		},
		multiplyY: {
			min: 1,
			max: 4
		}
	};

	this.maxDimensions = null;

	this.group = null;

	this.selectable = true;

	this.useCutImage = false;
	this.useExtractedShape = false;

	this.randomImagePositionMultipliers = {};

	this.setImageOptions = function (img) {
		img.selectable = true;
		img.centeredRotation = true;
		img.hasBorders = false;
		img.lockUniScaling = true;
	};

	this.add = function (img) {
		if (img.type !== "image") {
			img = new fabric.Image(img);
		} else {
			img = new fabric.Image(img.getElement());
		}
		this.setImageOptions(img);
		this.images.push(img);

		return this.images.length - 1;
	};

	/**
	 * Add image to the veneer from a dataurl
	 * @param {type} dataurl
	 * @returns {undefined}
	 */
	this.addCutImageFromDataURL = function(dataurl) {
		var self = this;

		fabric.Image.fromURL(dataurl, function (cutimage) {
			useCutImage = true;
			self.clear();
			self.add(cutimage);
			Renderer.render();
		});
	};

	this.setMaxImageDimensions = function() {
		var canvasDimensions = Renderer.getCanvasDimensions();
		this.maxDimensions = canvasDimensions;
		this.maxDimensions.width = canvasDimensions.width - Settings.colorPreviewMinSize * 2;
	};

	// set the images on the canvas
	this.setImages = function(config) {
		var imgIndex = 0,
			j = 0;

		var veneerCount = ConfigurationUtility.getVeneersAmount(config),
			totalVeneerCount = veneerCount * this.modifiers.multiplyX * this.modifiers.multiplyY;

		this.clear();

		for (var i = 0; i < totalVeneerCount; i++) {
			var img = null;

			if (this.useCutImage) {
				// load previously cut image
				img = this.cutImages[imgIndex]._element;
			} else if (typeof config[j].useImage !== "undefined") {
				// load previously used image
				img = this.images[config[j].useImage];
				/*} else if (typeof this.getColoredImage(j) !== "undefined") {
				 // color image before use
				 img = this.getColoredImage(j);*/
				/*} else if (typeof this.getCroppedImage(j) !== "undefined") {
				 // crop image before use
				 img = this.getCroppedImage(j);*/
			} else {
				// use normal image
				img = this.originalImages[imgIndex];
			}

			this.add(img);

			if ((this.modifiers.mixmatch || config.forceMixMatch) && useCutImage !== true) {
				imgIndex++;
				if (imgIndex >= this.imageCount) {
					imgIndex = 0;
				}
			}

			j++;
			if (j === veneerCount) {
				j = 0;
			}
		}
	};

	/**
	 * Sets dimensions for all images
	 * @returns {boolean}
	 */
	this.setImagesDimensions = function(config) {
		var j = 0;
		for (var i = 0; i < this.images.length; i++) {
			var img = this.images[i],
				conf = ConfigurationUtility.getVeneerConfig(config, j);

			img = ImageUtility.scaleToHeight(img, Math.min(this.maxDimensions.height, img.getHeight()));

			// scaling
			if (conf.scale) {
				img = ImageUtility.scaleImage(img, conf.scale);
			}

			// cropping
			/*
			 if (typeof this.getCroppedImage(j) === "undefined" && (cropImage(img, conf, j) || cropPolygon(img, conf, j))) {
			 return false;
			 }

			 if (typeof this.getColoredImage(j) === "undefined" && colorImage(img, j)) {
			 return false;
			 }
			 */

			j++;
			if (j === ConfigurationUtility.getVeneersAmount(config)) {
				j = 0;
			}
		}
		if (typeof config.veneersPerColumn === "undefined") {
			config.veneersPerColumn = 1;
		}

		return true;
	};

	/**
	 * Applies all positional modifiers found in the specific configuration
	 * @returns {fabric.Group|VeneerDesigner@call;rotateGroup|VeneerDesigner.setImagePositions.group}
	 */
	this.setImagePositions = function(config) {
		var currentPos = {x: 0, y: 0},
			imageDimensions = [],
			group = new fabric.Group(),
			j = 0,
			multiplyX = this.modifiers.multiplyX,
			multiplyY = this.modifiers.multiplyY,
			currentRowPos = 0,
			preventDefaultMove = true;

		group.oid = this.oid;
		group.selectable = this.selectable;
		group.centeredRotation = true;
		group.setOriginX('center');
		group.setOriginY('center');
		group.set(Settings.SelectionBoxStyles);

		for (var i = 0; i < this.images.length; i++) {
			var img = this.images[i],
				conf = config[j];

			// decrease dimensions slightly to prevent gaps
			imageDimensions[i] = {width: Math.floor(img.getWidth()) - 1, height: Math.floor(img.getHeight()) - 1};

			// rotate images
			imageDimensions[i] = this.rotateImage(img, conf, imageDimensions[i], config);

			// apply various modifiers
			this.setModifiers(img, conf.modifiers);

			// move pointer for next image
			ConfigurationUtility.movePointer(conf, currentPos, imageDimensions, preventDefaultMove, this);

			// set position
			img.setLeft(currentPos.x);
			img.setTop(currentPos.y);
			img.setCoords();

			group.addWithUpdate(img);

			preventDefaultMove = false;
			j++;
			if (j === ConfigurationUtility.getVeneersAmount(config)) {
				if (typeof config.extract !== "undefined") {
					CanvasUtility.extractShape(config.extract, group);
					// end prematurely and use the extracted image from now on
					return false;
				}
				// repeating imagegroup
				j = 0;
				// reset position
				var resetPosition = null;
				if (multiplyX > 1) {
					multiplyX--;
					resetPosition = config.resetPositionX;
					if (typeof config.resetPositionX === "undefined") {
						resetPosition = {moveY: currentRowPos};
					}
				} else if (multiplyY > 1) {
					multiplyY--;
					multiplyX = this.modifiers.multiplyX;
					resetPosition = config.resetPositionY;
					if (typeof config.resetPositionY === "undefined") {
						resetPosition = {moveX: 0, moveY: {direction: 'bottom', by: 'height'}};
					}
					preventDefaultMove = true;
				}
				if (resetPosition !== null) {
					ConfigurationUtility.movePointer(ConfigurationUtility.formatMoveCommands(resetPosition), currentPos, imageDimensions, true, this);
					currentRowPos = currentPos.y;
				}
			}
		}

		if (typeof config.rotate !== "undefined") {
			var rotate = config.rotate;
			group = this.rotateGroup(group, rotate.angle);
			var factor = Math.sin(fabric.util.degreesToRadians(rotate.angle));
			group.top += imageDimensions[rotate.image].width * factor;
			group.left += imageDimensions[rotate.image].height * factor;

			group.left += imageDimensions[rotate.image][rotate.dimensionX] * rotate.multiplierX * this.modifiers.multiplyX;
			group.top += imageDimensions[rotate.image][rotate.dimensionY] * rotate.multiplierY * this.modifiers.multiplyX;
		}

		/*
		if (this.modifiers.rotationChanged) {
			group = this.rotateGroup(group, this.modifiers.rotate);
			group.top = Math.floor(group.width);
			if (config.onRotate) {
				var d = imageDimensions[0][config.onRotate.by];
				if (config.onRotate.direction === 'left') {
					d *= -1;
				}
				group.left += d;
			}
		}
		*/

		if (this.modifiers.fit) {
			// resize zo fit everything on the canvas
			CanvasUtility.fitGroupToCanvas(group, this.maxDimensions.width);
		}

		return group;
	};

	this.center = function() {
		Renderer.getCanvas().centerObject(this.group);
		this.position.left = this.group.left;
		this.position.top = this.group.top;
		this.centered = true;
		this.setPosition();
	};

	/**
	 * rotate image and calculate its new dimensions
	 * @param {fabric.Image} img
	 * @param {object} conf
	 * @param {object} imageDimensions
	 * @param {object} config
	 * @returns {object}
	 */
	this.rotateImage = function(img, conf, imageDimensions, config) {
		if (conf.rotate) {
			img.setAngle(conf.rotate);
			// catch tan undefined
			if (Math.abs(conf.rotate) === 90 || Math.abs(conf.rotate) === 270) {
				var temp = imageDimensions.width;
				imageDimensions.width = imageDimensions.height;
				imageDimensions.height = temp;
				return imageDimensions;
			}
			// rotate image
			var alpha = fabric.util.degreesToRadians(conf.rotate),
				// Die Breite des Furniers wird nach dem Rotieren zur hypothenuse des ersten dreiecks
				c = imageDimensions.width,
				// Winkelsatz zum berechnen der ankathete
				b = Math.abs(Math.cos(alpha)) * c,
				// beide Dreiecke teilen sich die hoehe und gleichzeitig eine Kante
				h = Math.sqrt(c * c - b * b),
				// gegehkathete des zweiten Dreiecks
				a2 = Math.abs(Math.tan(alpha)) * h,
				factor = Math.abs(Math.sin(alpha)) + Math.abs(Math.cos(alpha));
			// calculate dimensions
			if (config.requireCut !== "diamond") {
				//imageDimensions.width *= factor;
				//imageDimensions.height *= factor;
				// ankathete des ersten und gegenkathete des zweiten dreiecks ergeben die Breite des oberen Bereichs des rotierten furniers
				imageDimensions.width = b + a2;
				imageDimensions.height += h;
			} else {
				imageDimensions.width = Math.floor(imageDimensions.width / factor);
				imageDimensions.height = Math.floor(imageDimensions.height / factor);
				img.setLeft(Math.floor(img.getLeft() + imageDimensions.width / 2));
				img.setTop(Math.floor(img.getTop() - imageDimensions.height / 2));
			}
		}
		return imageDimensions;
	};

	/**
	 * Set Modifier to this object
	 * @param modifier
	 * @param value
	 */
	this.setModifier = function(modifier, value) {
		this.modifiers[modifier] = value;

		var limits = this.modifierLimits[modifier];
		if(limits !== undefined) {
			this.modifiers[modifier] = Math.min(limits.max, Math.max(limits.min, value));
		}
	};

	this.getModifier = function(modifier) {
		return this.modifiers[modifier];
	};

	/**
	 * Set Image modifiers from configuration
	 * @param {fabric.Image} img
	 * @param {object} modifiers
	 * @returns {undefined}
	 */
	this.setModifiers = function(img, modifiers) {
		if(modifiers !== undefined) {
			for (var i = 0; i < modifiers.length; i++) {
				var mod = modifiers[i];
				img.set(mod.modifier, mod.value);
			}
		}
	};

	/**
	 * Rotate a group of images
	 * @param {type} group
	 * @returns {object}
	 */
	this.rotateGroup = function(group, angle) {
		this.position.angle = angle;
		this.modifiers.rotationChanged = false;

		return group;
	};

	this.clear = function () {
		this.images = [];
		this.images.length = 0;
	};

	this.setPosition = function() {
		for(var key in this.position) {
			this.group.set(key, this.position[key]);
		}
		this.group.setCoords();
	};

	this.isOutsideBounds = function() {
		var boundingRect = this.group.getBoundingRect(),
			canvasDimensions = Renderer.getCanvasDimensions();

		if(
			boundingRect.left > canvasDimensions.width - Settings.outOfBoundsTolerance
			|| boundingRect.top > canvasDimensions.height - Settings.outOfBoundsTolerance
			|| boundingRect.left + boundingRect.width < Settings.outOfBoundsTolerance
			|| boundingRect.top + boundingRect.height < Settings.outOfBoundsTolerance
		) {
			return true;
		}

		return false;
	};

	ImageUtility.loadImages(this);
}