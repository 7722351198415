var VeneerColorModule = (function() {

	function colorImage(img, j) {
		if (veneer.modifiers.colorVeneer) {
			var coloredImage = new fabric.Image(img.getElement()),
				filter = new fabric.Image.filters.Tint({
					color: self.veneer.modifiers.veneerColor,
					opacity: parseFloat($('#veneercolor_opacity').val())
				});
			coloredImage.filters.push(filter);
			coloredImage.applyFilters(function () {
				veneer.addColoredImage(coloredImage, j);
				_render();
			});

			return true;
		}
		return false;
	}

	return {

	}
}());