var IntarsiaModule = (function() {

	function renderIntarsias() {
		for (var i = 0; i < veneer.intarsias.length; i++) {
			var img = veneer.getIntarsia(i);
			if (img !== null) {
				Renderer.getCanvas().add(img);
			}
		}
	}

	function hideIntarsias() {
		for (var i = 0; i < veneer.intarsias.length; i++) {
			var img = veneer.getIntarsia(i);
			if (img !== null) {
				Renderer.getCanvas().remove(img);
			}
		}
		Renderer.getCanvas().renderAll();
	}

	return {

	}
}());