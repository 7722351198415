/**
 * Utility Module for parsing and applying the veneerDesignerConfig object
 * @type {{getConfig, getVeneersAmount, getVeneerConfig, formatMoveCommands, movePointer}}
 */
var ConfigurationUtility = (function() {

	var lastRandomNumber = 0;

	/**
	 * Load the config for a specific jointing configuration
	 * @param veneer
	 */
	function getConfig(veneer) {
		var config = JointingConfigurations[veneer.getModifier('jointing')];

		if (veneer.useExtractedShape && typeof config.extractConfig !== "undefined") {
			config = config.extractConfig;
		}

		return config;
	}

	/**
	 * Returns the total amount of veneers needed for a given jointing configuration
	 * @param config
	 * @returns {*}
	 */
	function getVeneersAmount(config) {
		if (typeof config.veneers !== "undefined") {
			return config.veneers;
		}

		// loop through all config steps to calculate the veneer amount if it was not explicitly stated in the config
		var i = 0;
		while (typeof config[i] !== "undefined") {
			i++;
		}

		config.veneers = i;

		return i;
	}

	/**
	 * get the formatted command config for a single step
	 * @param config
	 * @param i
	 * @returns {object}
	 */
	function getVeneerConfig(config, i) {
		return formatMoveCommands(config[i]);
	}

	/**
	 * Formats shorthand properties of the config object
	 * @param conf
	 * @returns {object}
	 */
	function formatMoveCommands(conf) {
		if (typeof conf.moveX !== "undefined") {
			formatMoveCommandShorthandProperties(conf.moveX);
		}
		if (typeof conf.moveY !== "undefined") {
			formatMoveCommandShorthandProperties(conf.moveY);
		}
		return conf;
	}

	function formatMoveCommandShorthandProperties(move) {
		if (typeof move.push === "function") {
			for (var i = 0, l = move.length; i < l; i++) {
				formatMoveCommandShorthandProperties(move[i]);
			}
			return;
		}
		if (typeof move.d !== "undefined") {
			move.direction = move.d;
		}
		if (typeof move.m !== "undefined") {
			move.multiplier = move.m;
		}
		if (typeof move.i !== "undefined") {
			move.image = move.i;
		}
		if (typeof move.by !== "undefined") {
			if (move.by === 'h') {
				move.by = 'height';
			}
			if (move.by === 'w') {
				move.by = 'width';
			}
		}
	}

	/**
	 * Move image on the canvas according to its configuration
	 * @param {object} conf move configuration
	 * @param {object} currentPos current pointer position
	 * @param {object} imageDimensions dimensions of all used images
	 * @param {integer} i current iterator
	 * @param {Veneer} veneer the veneer object
	 * @returns {undefined}
	 * todo refactor
	 */
	function movePointer(conf, currentPos, imageDimensions, preventDefaultMove, veneer) {
		// move to the right if no move command is given unless its the first veneer or moveX is none
		if ((typeof conf.moveX !== "undefined" && conf.moveX !== 'none') || (typeof conf.moveY === "undefined" && typeof conf.moveX === "undefined" && preventDefaultMove !== true)) {
			// prevent overwriting configuration by saving in temporary variable
			var moveX = conf.moveX;
			if (typeof moveX === "number") {
				currentPos.x = moveX;
			} else {
				if (typeof moveX === "undefined") {
					moveX = {direction: 'right', by: 'width'};
				}
				if (moveX === 'right' || moveX === 'left') {
					moveX = {direction: conf.moveX, by: 'width'};
				}
				// cleaner way to detect array?
				// multiple commands
				if (typeof moveX.push === "function") {
					for (var j = 0; j < conf.moveX.length; j++) {
						// recursion for multiple commands
						movePointer({moveX: conf.moveX[j]}, currentPos, imageDimensions, preventDefaultMove, veneer);
					}
				} else {
					var by = getMoveByValue(moveX, imageDimensions, moveX.image, veneer);
					currentPos.x = getPosition(by, currentPos.x, moveX.direction);
				}
			}
		}
		if (typeof conf.moveY !== "undefined" && conf.moveY !== 'none') {
			var moveY = conf.moveY;
			if (typeof conf.moveY === "number") {
				currentPos.y = moveY;
			} else {
				if (moveY === 'top' || moveY === 'bottom') {
					moveY = {direction: conf.moveY, by: 'height'};
				}
				// multiple commands
				if (typeof moveY.push === "function") {
					for (var j = 0; j < conf.moveY.length; j++) {
						movePointer({moveY: conf.moveY[j]}, currentPos, imageDimensions, preventDefaultMove, veneer);
					}
				} else {
					var by = getMoveByValue(moveY, imageDimensions, moveY.image, veneer);
					currentPos.y = getPosition(by, currentPos.y, moveY.direction);
				}
			}
		}
	}

	/**
	 * get value to move image by
	 * @param {object} imageDimensions
	 * @param {object} conf
	 * @param {object} imageIndex
	 * @param {Veneer} veneer the veneer object
	 * @returns {number}
	 * todo refactor
	 */
	function getMoveByValue(conf, imageDimensions, imageIndex, veneer) {
		if (typeof imageIndex === "undefined") {
			imageIndex = Math.max(imageDimensions.length - 2, 0);
		}
		if (typeof conf.multiplier === "undefined") {
			conf.multiplier = 1;
		}
		var multiplier = conf.multiplier;
		if (typeof conf.multiplier === "object") {
			if (typeof conf.multiplier.length === "undefined") {
				// check if a multiplier was already randomised and reuse it
				if(veneer.randomImagePositionMultipliers[imageIndex] !== undefined) {
					multiplier = veneer.randomImagePositionMultipliers[imageIndex];
				} else {
					// object -> random number
					multiplier = lastRandomNumber = utilities.getRandomNumber(conf.multiplier.min, conf.multiplier.max, lastRandomNumber, conf.multiplier.minDifference)
					veneer.randomImagePositionMultipliers[imageIndex] = multiplier;
				}
			} else {
				// array -> iterate numbers
				multiplier = conf.multiplier.shift();
				conf.multiplier.push(multiplier);
			}
		}
		if (typeof conf.by === "undefined") {
			if (conf.direction === 'top' || conf.direction === 'bottom') {
				conf.by = 'height';
			} else {
				conf.by = 'width';
			}
		}
		return Math.floor(imageDimensions[imageIndex][conf.by] * multiplier);
	}

	/**
	 * Get new image position
	 * @param {number} d
	 * @param {number} pos
	 * @param {string} direction
	 * @returns {unresolved}
	 */
	function getPosition(d, pos, direction) {
		if (direction === 'left' || direction === 'top') {
			d = -d;
		}

		pos += d;
		return Math.round(pos);
	}

	return {
		getConfig: getConfig,
		getVeneersAmount: getVeneersAmount,
		getVeneerConfig: getVeneerConfig,
		formatMoveCommands: formatMoveCommands,
		movePointer: movePointer
	}
}());