var ImageDownloadModule = (function() {

	function downloadImage(event) {
		var target = $(event.delegateTarget);

		target.attr('href', Renderer.getCanvas().toDataURL({format: 'jpeg'}));
		target.attr('download', "canvas.jpg");
	}

	return {
		downloadImage: downloadImage
	};

}());