var Scene = (function() {

	var veneers = [],
		currentVeneer = 0;

	function add(veneer) {
		veneers.push(veneer);

		currentVeneer = veneers.length-1;
		return veneer;
	}

	function remove(index) {
		return veneers.splice(index, 1)[0];
	}

	function getVeneers() {
		return veneers;
	}

	function getVeneer(index) {
		return veneers[index];
	}

	function getCurrentVeneer() {
		return veneers[currentVeneer];
	}

	function getCurrentVeneerIndex() {
		return currentVeneer;
	}

	function setCurrentVeneer(canvasObject) {
		for(var i = 0, l = veneers.length; i < l; i++) {
			if(veneers[i].group === canvasObject) {
				return setCurrentVeneerByIndex(i);
			}
		}
	}

	function setCurrentVeneerByIndex(index) {
		var veneer = veneers[index];

		remove(index);
		add(veneer);
		veneer.group.bringToFront();

		// update tool title with current veneer name
		InterfaceEvents.setToolTitle(veneer.name);

		return veneer;
	}

	return {
		add: add,
		remove: remove,
		getVeneers: getVeneers,
		getVeneer: getVeneer,
		getCurrentVeneer: getCurrentVeneer,
		getCurrentVeneerIndex: getCurrentVeneerIndex,
		setCurrentVeneer: setCurrentVeneer,
		setCurrentVeneerByIndex: setCurrentVeneerByIndex
	};
}());