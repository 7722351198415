/**
 * This module handles all image loading processes as well as initial image resizing
 * @type {{loadImages, getMaterialImage, scaleToHeight, scaleToWidth, scaleImage}}
 */
var ImageUtility = (function() {

	// cache image data to reduce continous ajax requests
	var veneerImageCache = {},
		materialImageCache = {},

		// allowed file types for image upload
		allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"];

	/**
	 * Load all original images for the given veneer
	 * @param {Veneer} veneer
	 */
	function loadImages(veneer) {
		veneer.originalImages = [];

		// get data from cache
		if(veneerImageCache[veneer.uid] !== undefined) {
			setVeneerData(veneer, veneerImageCache[veneer.uid]);
			_loadImages(veneer, 0);
			return;
		}

		// todo error handling
		// todo some loading notification
		$.ajax({
			type: "POST",
			url: window.location.href,
			data: {type: 333, ajax: true, action: 'toolimages', veneer: veneer.uid},
			success: function(response) {
				var json = getJson(response);
				// set data to cache
				veneerImageCache[veneer.uid] = json;

				setVeneerData(veneer, json);
				_loadImages(veneer, 0);
			}
		});
	}

	function setVeneerData(veneer, data) {
		veneer.name = data.name;
		veneer.imageUrls = data.images;
		veneer.imageCount = data.images.length;
	}

	/**
	 * Load a single image
	 * @param veneer
	 * @param i
	 * @private
	 */
	function _loadImages(veneer, i) {
		var img = new Image();

		img.onload = function() {
			veneer.originalImages[i] = img;

			if(veneer.originalImages.length === veneer.imageCount) {
				resizeOriginalImages(veneer);
				// signal that all images have been loaded
				VeneerDesigner.imagesLoaded();
			} else {
				_loadImages(veneer, ++i);
			}
		};

		img.onerror = function() {
			veneer.imageCount--;

			if(veneer.originalImages.length === veneer.imageCount) {
				resizeOriginalImages(veneer);
				// signal that all images have been loaded
				VeneerDesigner.imagesLoaded();
			} else {
				_loadImages(veneer, ++i);
			}
		};

		img.src = veneer.imageUrls[i];
	}

	this.resizeOriginalImages = function(veneer) {
		if(veneer.imageCount === 1) {
			return;
		}

		// todo: rework to use cropping instead of resizing (really?)
		// rough resize
		// get lowest width
		var minWidth = veneer.originalImages[0].width;
		var height = veneer.originalImages[0].height;

		for(var i = 1; i < veneer.originalImages.length; i++) {
			if(veneer.originalImages[i].width < minWidth) {
				minWidth = veneer.originalImages[i].width;
				height = veneer.originalImages[i].height;
			}
		}

		for(var i = 0; i < veneer.originalImages.length; i++) {
			veneer.originalImages[i].width = minWidth;
			veneer.originalImages[i].height = height;
		}
	};

	/**
	 * Load material background images
	 * @param id
	 * @param side
	 */
	function getMaterialImage(id, side) {
		if(materialImageCache[id] !== undefined) {
			setMaterialImage(materialImageCache[id], side);
		}

		$.ajax({
			type: "POST",
			url: window.location.href,
			data: {type: 333, ajax: true, action: 'getMaterial', materialid: id}
		}).success(function(result) {
			var json = getJson(result);
			materialImageCache[id] = json.url;

			setMaterialImage(json.url, side);
		});
	}

	/**
	 * Generate fabric object from material image url
	 * @param url
	 * @param side
	 */
	function setMaterialImage(url, side) {
		fabric.Image.fromURL(url, function(image) {
			Renderer.setMaterial(side, image);
			Renderer.render();
		});
	}

	/**
	 * Scale image to height value
	 * @param {fabric.Image} img
	 * @param {float} height
	 * @returns {fabric.Image}
	 */
	function scaleToHeight(img, height) {
		var ratio = img.getHeight() / img.getWidth();

		img.setHeight(height);
		img.setWidth(height / ratio);

		return img;
	}

	/**
	 * Scale image to width value
	 * @param {fabric.Image} img
	 * @param {float} width
	 * @returns {fabric.Image}
	 */
	function scaleToWidth(img, width) {
		var ratio = img.getHeight() / img.getWidth();

		img.setWidth(width);
		img.setHeight(width * ratio);

		return img;
	}

	function scaleImage(img, scale) {
		img.setWidth(img.getWidth() * scale);
		img.setHeight(img.getHeight() * scale);

		return img;
	}

	function loadUserImageIntoCanvas(file, pos) {
		if ($.inArray(file.type, allowedFileTypes) > -1) {
			var reader = new FileReader();

			reader.onload = function () {
				fabric.Image.fromURL(reader.result, function (intarsia) {
					if (typeof pos !== "undefined") {
						intarsia.setLeft(pos.x);
						intarsia.setTop(pos.y);
					}

					veneer.addIntarsia(intarsia, pos);
					Renderer.render();
				});
			};

			reader.readAsDataURL(file);
		}
	}

	return {
		loadImages: loadImages,
		getMaterialImage: getMaterialImage,
		scaleToHeight: scaleToHeight,
		scaleToWidth: scaleToWidth,
		scaleImage: scaleImage
	};
}());