var CroppingModule = (function() {

	function cropImage(img, conf, j) {
		if (conf.crop) {
			var crop = conf.crop,
				x = Math.round(img.getWidth() * crop.left),
				// prevents misalignment issues when y value is 0
				y = Math.max(Math.round(img.getHeight() * crop.top), 1),
				width = Math.round(img.getWidth() * crop.width),
				height = Math.round(img.getHeight() * crop.height);

			if (crop.format) {
				if (typeof crop.height === "undefined") {
					height = Math.round(width * crop.format);
				} else if (typeof crop.width === "undefined") {
					width = Math.round(height * crop.format);
				}
			}

			var dataurl = img.toDataURL({left: x, top: y, width: width, height: height});

			trimImage(dataurl, function (dataurl) {
				addCroppedImageFromDataURL(dataurl, j);
			}, this);

			return true;
		}
		return false;
	}

	function cropPolygon(img, conf, j) {
		if (conf.cropPolygon) {
			var canvas = createTempCanvas(),
				psc = new fabric.StaticCanvas();
			psc.setDimensions({width: img.getWidth(), height: img.getHeight()});
			psc.add(img);
			if (typeof conf.cropPolygon.angle !== "undefined") {
				img.setAngle(conf.cropPolygon.angle);
			}
			img.setLeft(conf.cropPolygon.offsetX);
			img.setTop(conf.cropPolygon.offsetY);
			psc.renderAll();

			var pattern = new fabric.Pattern({
					source: psc.getElement(),
					repeat: 'repeat',
				}),
				polygon = new fabric.Polygon(conf.cropPolygon.points, {
					fill: pattern,
					top: 0,
					left: 0
				});
			Renderer.getCanvas().setDimensions({width: Math.floor(polygon.width), height: Math.floor(polygon.height)});
			Renderer.getCanvas().add(polygon);
			Renderer.getCanvas().renderAll();

			var dataurl = Renderer.getCanvas().toDataURL();
			Renderer.getCanvas().dispose();
			canvas = null;

			addCroppedImageFromDataURL(dataurl, j);
			return true;
		}
		return false;
	}

	/*
	 calcPolygonPoints = function(conf, img) {
	 var points = [];
	 for(var i = 0, l = conf.length; i < l; i++) {
	 var x = Math.round(conf[i][0] * img.width),
	 y = Math.round(conf[i][1] * img.width);
	 points.push({x: x, y: y});
	 }
	 return points;
	 }
	 */

	function cropImageTo(img, width, height, x, y, callback) {
		var left = x || 0,
			top = y || 0,
			dataurl = img.toDataURL({left: left, top: top, width: Math.min(width, img.getWidth()), height: Math.min(height, img.getHeight())});

		fabric.Image.fromURL(dataurl, callback);
	}

	function addCroppedImageFromDataURL(dataurl, j) {
		fabric.Image.fromURL(dataurl, function (croppedImage) {
			veneer.addCroppedImage(croppedImage, j);
			_render();
		});
	}

	return {

	}
}());