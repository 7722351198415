/**
 * Canvas Utility module
 * @type {{setCanvasDimensions, extractShape, fitGroupToCanvas, getImagedata}}
 */
var CanvasUtility = (function() {

	/**
	 * Set the canvas to fill the available space
	 * @param canvas
	 */
	function setCanvasDimensions(canvas) {
		var $canvasContainer = $('#sg_tool_container .' + canvas.containerClass),
			$canvasWrapper = $canvasContainer.parent(),
			width = $canvasWrapper.width(),
			height = Math.min(Settings.canvasMaxHeight, window.innerHeight - $canvasContainer.position().top - Settings.canvasBottomMargin);

		canvas.setDimensions({width: width, height: height});
	}

	/**
	 * Extract a shape from the current configuration
	 * @param {object} extract
	 * @param {fabric.Group} group
	 * @returns {undefined}
	 */
	function extractShape(veneer, extract, group) {
		if (extract.shape === "square") {
			// render the current configuration on a temporary canvas
			var canvas = createTempCanvas(),
				ctx = canvas.getContext();
			canvas.setDimensions({width: group.getWidth(), height: group.getHeight()});
			canvas.add(group);
			canvas.renderAll();

			// get the extracted shape
			var center = {x: group.left + group.width / 2, y: group.top + group.height / 2},
				dx = (group.width / 2) * extract.width,
				dy = (group.height / 2) * extract.height,
				tl = {x: center.x - dx, y: center.y - dy},
				br = {x: center.x + dx, y: center.y + dy},
				imageData = ctx.getImageData(tl.x, tl.y, br.x - tl.x, br.y - tl.y);

			// get the new image
			canvas.setDimensions({width: Math.floor(group.width * extract.width), height: Math.floor(group.height * extract.height)});
			ctx.putImageData(imageData, 0, 0);
			var dataurl = canvas.lowerCanvasEl.toDataURL();

			trimImage(dataurl, function (dataurl) {
				// use the extracted image for further rendering
				veneer.clearCutImages();
				useExtractedShape = true;
				// restarts the render process with the extracted image
				veneer.addCutImageFromDataURL(dataurl);
			}, this);

			canvas.dispose();
			canvas = null;
		}
	}

	/**
	 * Trim a image of whitespace on the top and left side
	 * @param {String} dataurl
	 * @param {function} callback
	 * @param {object} context
	 * @returns {undefined}
	 */
	function trimImage(dataurl, callback, context) {
		var canvas = createTempCanvas();

		fabric.Image.fromURL(dataurl, function (image) {
			var cd = {width: image.getWidth(), height: image.getHeight()};
			canvas.setDimensions(cd);
			canvas.add(image);
			canvas.renderAll();

			_trimImage(canvas, image, cd, 'top');
			_trimImage(canvas, image, cd, 'left');
			//_trimImage(canvas, image, cd, 'bottom');

			dataurl = canvas.lowerCanvasEl.toDataURL();
			canvas.dispose();
			canvas = null;

			callback.call(context, dataurl);
		});
	}

	function _trimImage(canvas, image, cd, dir) {
		var ctx = canvas.getContext(),
			trim = true,
			x = 0,
			y = 0,
			width = 1,
			height = 1,
			dx = 0,
			dy = 0;
		if (dir === "top") {
			width = image.getWidth();
			dy = 1;
		}
		if (dir === "left") {
			height = image.getHeight();
			dx = 1;
		}
		if (dir === "bottom") {
			y = image.getHeight() - 1;
			dy = -1;
		}
		// move the image until it clips to the canvas borders
		while (trim) {
			var id = ctx.getImageData(x, y, width, height);
			trim = true;
			for (var i = 3; i < id.data.length; i += 4) {
				if (id.data[i] > Settings.trimTolerance) {
					trim = false;
				}
			}
			if (trim) {
				image.setLeft(image.getLeft() - dx);
				image.setTop(image.getTop() - dy);
				cd.width -= dx;
				cd.height -= dy;
				canvas.setDimensions(cd);
				canvas.renderAll();
			}
		}
	}

	function fitGroupToCanvas(group, maxWidth) {
		if (group.getWidth() > maxWidth) {
			group.scale(utilities.round(maxWidth / group.getWidth(), 2));
		}
	}

	function getImagedata() {
		return Renderer.getCanvas().getContext().getImageData(0, 0, Renderer.getCanvas().width, Renderer.getCanvas().height);
	}

	function createTempCanvas() {
		var canvasEl = fabric.util.createCanvasElement();
		return new fabric.StaticCanvas(canvasEl);
	}

	return {
		setCanvasDimensions: setCanvasDimensions,
		extractShape: extractShape,
		fitGroupToCanvas: fitGroupToCanvas,
		getImagedata: getImagedata
	}
}());