/**
 * module for the veneer gallery inside the designer
 * @type {{showGallery, closeGallery}}
 */
var VeneerGallery = (function() {

	var currentType = 'gallery',
		currentSort = 0,
		galleryLoaded = false,
		$filterForm = null,
		checkboxOverlays = null;

	function showGallery(e) {
		e.preventDefault();

		var container = $('.sg_gallery_container');
		container.addClass('sg_showGallery');

		// load the gallery only once
		if (galleryLoaded === false) {
			utilities.ajax({
				action: 'gallery',
				showOptions: true,
				veneerDesignerView: true
			}, function (response) {
				galleryLoaded = true;
				afterShowGallery(response, false, true);
				initDropDownFilters();
			});
		}
	}

	function afterShowGallery(response, container, delayResize) {
		container = container || '.sg_gallery_container';

		$(container).html(response);

		var $designerGallery = $('#sg_tool_container .sg_gallery_container .sg_categories, #sg_tool_container .sg_gallery_container .sg_galleryview');
		$filterForm = $('#sg_tool_container .sg_filter_form');

		centerVeneerGallery();
		checkboxOverlays = initPickers('#sg_tool_container', true);
		setVeneerAddHandlers();

		// resize can be delayed to take the gallery opening animation into account
		if (delayResize === true) {
			setTimeout(function () {
				utilities.expandHeightToBottom($designerGallery);
			}, 300);
		} else {
			utilities.expandHeightToBottom($designerGallery);
		}
	}

	function initDropDownFilters() {
		var filters = $('#sg_tool_container .sg_dropdownFilter');
		for(var i = 0, l = filters.length; i < l; i++) {
			new dropdownFilter(filters[i]);
		}
	}

	/**
	 * Overwrite usual gallery handlers with veneerDesigner specific handlers
	 */
	function setVeneerAddHandlers() {
		$('#sg_tool_container .sg_veneerAdd').on('click', function (e) {
			VeneerListModule.addVeneer(e);
			closeGallery();
		});

		$('#sg_tool_container .sg_listviewlink, #sg_tool_container .sg_galleryviewlink').off('click');
		$('#sg_tool_container .sg_listviewlink, #sg_tool_container .sg_galleryviewlink').on('click', function (e) {
			e.preventDefault();

			$('#sg_tool_container .sg_listviewlink, #sg_tool_container .sg_galleryviewlink').removeClass('act');
			$(this).addClass('act');

			utilities.ajax({
				showOptions: false,
				veneerDesignerView: true,
				filter: getFilter($filterForm[0])
			}, function (response) {
				afterShowGallery(response, '.sg_gallery_container .sg_container');
			}, $(this).attr('href'));

			if ($(this).hasClass('sg_listviewlink')) {
				$('#sg_tool_container .sg_sort_switcher').show();
				currentType = 'list';
			} else {
				$('#sg_tool_container .sg_sort_switcher').hide();
				currentType = 'gallery';
			}
		});

		$('#sg_tool_container .sg_listviewlink_sort').off('click');
		$('#sg_tool_container .sg_listviewlink_sort').on('click', function (e) {
			e.preventDefault();

			$(this).parents('ul').find('a').removeClass('act');
			$(this).addClass('act');
			currentSort = $(this).data('sort');

			utilities.ajax({
				showOptions: false,
				veneerDesignerView: true,
				filter: getFilter($filterForm[0])
			}, function (response) {
				afterShowGallery(response, '.sg_gallery_container .sg_container');
			}, $(this).attr('href'));
		});

		// intercept letterlinks
		$('#sg_tool_container .sg_letterlink').off('click');
		$('#sg_tool_container .sg_letterlink').on('click', function (e) {
			e.preventDefault();
			var letter = $(this).data('letter');
			var scrollGoal = $('#sg_tool_container #sg_category_' + letter);
			$("#sg_tool_container .sg_categories").animate({
				scrollTop: scrollGoal[0].offsetTop
			});
		});

		$('#sg_tool_container .sg_filter_checkbox, #sg_tool_container .sg_filter_select').off('change');
		$('#sg_tool_container .sg_dropdownFilterDropdown input').off('click');
		$('#sg_tool_container .sg_filter_checkbox, #sg_tool_container .sg_filter_select').on('change', changeFilter);
		$('#sg_tool_container .sg_dropdownFilterDropdown input').on('click', changeFilter);

		$('#closeGallery').on('click', closeGallery);
	}

	function changeFilter(e) {
		utilities.ajax({
			action: currentType,
			sort: currentSort,
			showOptions: false,
			veneerDesignerView: true,
			filter: getFilter($filterForm[0])
		}, function(response) {
			if(currentType === 'gallery') {
				afterShowGallery($(response).find('.sg_galleryview').html(), '#sg_tool_container .sg_galleryview');
			}
			if(currentType === 'list') {
				if(currentSort === 0) {
					afterShowGallery($(response).find('.sg_listview').html(), '#sg_tool_container .sg_listview');
				} else {
					afterShowGallery($(response).find('.sg_sorted_listview').html(), '#sg_tool_container .sg_sorted_listview');
				}
			}
		});
	}

	function getFilter(form) {
		var filter = {};

		for(var i = 0, l = form.elements.length; i < l; i++) {
			var el = form.elements[i];
			// filter buttons
			if(el.type === "submit") {
				continue;
			}
			// filter unchecked radios / checkboxes
			if((el.type === "checkbox" || el.type === "radio") && el.checked === false) {
				continue;
			}
			// get input field name
			var isArray = el.name.match(/\[\]/),
				name = el.name.replace(/^filter|\[|\]/g, '');

			// push values into object
			if(isArray === null) {
				filter[name] = el.value;
			} else {
				if(filter[name] === undefined) {
					filter[name] = [];
				}
				filter[name].push(el.value);
			}
		}

		filter.availability = checkboxOverlays.availabilityCheckboxes.getValue();
		filter.pricegroup = checkboxOverlays.pricegroupCheckboxes.getValue();

		return filter;
	}

	/**
	 * Close the gallery and clear any ongoing veneer replacements
	 */
	function closeGallery() {
		VeneerListModule.stopReplacingVeneer();
		$('.sg_gallery_container').removeClass('sg_showGallery');
	}

	return {
		showGallery: showGallery,
		closeGallery: closeGallery,
		changeFilter: changeFilter
	}
}());