var TabsModule = (function() {

	function init() {
		$('.tabHeaders span').off('click');
		$('.tabHeaders span').on('click', changeTab);
	}

	function changeTab(event) {
		var target = $(event.delegateTarget),
			tabHeaders = target.parent().find('span'),
			tabs = target.parent().next('.tabs').find('.tab');

		tabHeaders.removeClass('act');
		target.addClass('act');

		tabs.hide();
		tabs.eq(target.index()).show();
	}

	return {
		init: init
	};

}());