// global pointer
var globalPointer = {},
activeTool,
chosenTool = 0,
lineDashAnim,
resizeDistance = 20,
endDistance = 20,
pointSize = 10,
curveInput = document.getElementById('curve'),
zoom = 1,
pointColor = '#000',
specialPointColor = '#f00',
pointHoverColor = '#0f0',
pathOptions = {
	stroke: 'black',
	fill: '',
	selectable: false,
	strokeDashArray: [10, 5],
	perPixelTargetFind: true,
};

/*
var image = new Image();
image.onload = function () {
	img = new fabric.Image(image);
	img.selectable = false;
	canvas.setDimensions({width: image.width, height: image.height});
	canvas.add(img);
	data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
	canvas.on('mouse:move', getPointer);
	document.addEventListener('keydown', onKeydown);
	canvas.upperCanvasEl.addEventListener('mousewheel', onMouseWheel);
	canvas.upperCanvasEl.addEventListener('DOMMouseScroll', onMouseWheel);
}
image.src = "veneer.png";
*/

var ToolStates = {
	incomplete: 0,
	complete: 1,
	dragging: 2,
	resizing: 3,
};

var startTool = function(ev) {
	if(typeof ev === "undefined") {
		ev = {};
	}

	var pointer = getPointer(ev.e);
	
	activeTool = getChosenTool(pointer.x, pointer.y);
	veneerDesigner.canvas.add(activeTool);
};

var getPointer = function(event) {
	var pointer;
	if(typeof event.e !== "undefined") {
		event = event.e;
	}
	
	if(typeof event.changedTouches !== "undefined") {
		pointer = veneerDesigner.canvas.getPointer(event.changedTouches[event.changedTouches.length-1]);
	} else {
		pointer = veneerDesigner.canvas.getPointer(event);
	}
	if(typeof pointer === "undefined" || isNaN(pointer.x) || isNaN(pointer.y)) {
		if(typeof activeTool === "undefined" || activeTool === null) {
			return globalPointer;
		}
		globalPointer = pointer;
		return activeTool.pointer;
	}
	globalPointer = pointer;
	return pointer;
};
/*
var onKeydown = function(event) {
	var key = event.keyCode,
	pointer = getPointer(event);
	if(typeof pointer.x === "undefined" || typeof pointer.y === "undefined") {
		pointer = {x: VeneerDesigner.canvas.width/2, y: VeneerDesigner.canvas.height/2};
	}
	
	// left
	if(key === 37) {
		pan(1, 0);
	}
	// up
	if(key === 38) {
		pan(0, 1);
	}
	// right
	if(key === 39) {
		pan(-1, 0);
	}
	// down
	if(key === 40) {
		pan(0, -1);
	}
};
*/
var zoomCanvas = function(p, dz) {
	if(dz > 0) {
		zoom += dz;
		veneerDesigner.canvas.zoomToPoint(p, zoom);
	} else {
		if(zoom > 1) {
			zoom += dz;
			veneerDesigner.canvas.zoomToPoint(p, zoom);
		}
		if(zoom === 1) {
			var p = new fabric.Point(0,0);
			veneerDesigner.canvas.absolutePan(p);
		}
	}
	if(typeof activeTool !== "undefined" && activeTool !== null) {
			activeTool.dimensionsChanged = true;
	}
};

var pan = function(x, y) {
	if(zoom > 1) {
		veneerDesigner.canvas.relativePan(new fabric.Point(x, y));
	}
};

var onMouseWheel = function(event) {
	event.preventDefault();
	console.log(event);
	if(event.type === 'mousewheel') {
		var dz = event.wheelDeltaY / 120;
	} else {
		console.log(event.detail);
		var dz = event.detail / -3;
	}
	
	var pointer = getPointer(event);
	p = new fabric.Point(pointer.x, pointer.y);
	zoomCanvas(p, dz);
};

var getChosenTool = function(x, y) {
	switch(chosenTool) {
		case 0:
			return new Lasso(x, y);
		case 1:
			return new CutFree(x, y);
		case 2:
			return new CutRect(x, y);
		case 3:
			return new CutCircle(x, y);
		case 4:
			return new CutTriangle(x, y);
		case 5:
			return new LassoRound(x, y);
	}
};

var CutTool = fabric.util.createClass(fabric.Path, {
	state: ToolStates.incomplete,
	offset: 0,
	selectedPixels: [],
	corners: [],
	poi: [],
	lastDrag: {},
	dimensionsChanged: true,
	points: [],
	pointer: {},
	shiftKey: false,
	ctrlKey: false,
	bounds: {},
	
	initialize: function(path, options) {
		veneerDesigner.canvas.off('mouse:move');
		veneerDesigner.canvas.off('mouse:down');
		veneerDesigner.canvas.off('touch:drag');
		veneerDesigner.setDrag(false);
		
		this.bounds = {left: 0, top: 0, right: veneerDesigner.canvas.width, bottom: veneerDesigner.canvas.height};
		
		// fking arrays fking prototype bs
		this.poi = [];
		this.corners = [];
		this.selectedPixels = [];
		this.points = [];
		
		if(fabric.isTouchSupported) {
			veneerDesigner.canvas.on('touch:drag', this.handleEvent.bind(this));
			resizeDistance = 30;
			endDistance = 30;
			pointSize = 16;
		}
		veneerDesigner.canvas.on({
			'mouse:move': this.handleEvent.bind(this),
			'mouse:down': this.handleEvent.bind(this),
			'mouse:up': this.handleEvent.bind(this),
		});
		this.callSuper('initialize', path, options);
	},
	
	beforeEvent: function(ev) {
		this.pointer = getPointer(ev.e);
		this.shiftKey = ev.e.shiftKey;
		this.ctrlKey = ev.e.ctrlKey;
		return ev.e.type;
	},
	
	handleEvent: function(ev) {
		if(typeof ev.e.preventDefault === "function") {
			ev.e.preventDefault();
		}
		var type = this.beforeEvent(ev);
		if(type === "mousemove" || type === "touchmove") {
			this.onMove(ev);
		}
		// IPad 1 fires undefined on touchend?
		if(type === "touchend") {
			this.onTouchEnd(ev);
		}
		if(type === "mousedown") {
			this.onMouseDown(ev);
		}
		if(type === "mouseup") {
			this.onMouseUp(ev);
		}
		if(type === "touchstart") {
			this.onTouchStart(ev);
		}
	},
	
	onMove: function(ev) {
		// show a preview before setting a subpath
		if(this.state === ToolStates.incomplete) {
			this.checkStartButtonHover();
			return this.previewPath();
		}
		// drag the complete path around
		if(this.state === ToolStates.dragging) {
			return this.drag();
		}
	},
	
	onMouseDown: function(ev) {
		this.dragOrNew(ev);
	},
	
	onMouseUp: function(ev) {
		this.stopDrag();
	},
	
	onTouchEnd: function(ev) {
		this.stopDrag();
	},
	
	onTouchStart: function(ev) {
		this.dragOrNew(ev);
	},
	
	// better name?
	dragOrNew: function(ev) {
		if(this.state === ToolStates.complete) {
			// reconstruct the selection to make it the active path
			this.reconstructPath();
			if(veneerDesigner.ctx.isPointInPath(this.pointer.x, this.pointer.y)) {
				// start dragging the path
				this.startDrag();
			} else {
				// create new path
				//this.destroy();
				//this.newTool(ev);
			}
		}
	},
	
	startDrag: function() {
		this.state = ToolStates.dragging;
		// use MovementX / movementY instead?
		this.lastDrag = {x: this.pointer.x, y: this.pointer.y};
		this.dimensionsChanged = true;
	},
	
	drag: function() {
		var diff = {x: this.lastDrag.x - this.pointer.x, y: this.lastDrag.y - this.pointer.y};
		
		diff = this.isDragAllowed(diff);
		// drag every point
		for(var i = 0; i < this.path.length - 1; i++) {
			var x = this.path[i][1] - diff.x,
			y = this.path[i][2] - diff.y;

			this.path[i] = ['L', x, y];
		}
		this.dragPoints(diff);
		
		veneerDesigner.canvas.renderAll();
	},
	
	isDragAllowed: function(diff) {
		for(var i = 0; i < this.path.length - 1; i++) {
			var x = this.path[i][1] - diff.x,
			y = this.path[i][2] - diff.y;
			if(x < this.bounds.left) {
				diff.x = this.path[i][1] - this.bounds.left;
			}
			if(x > this.bounds.right) {
				diff.x = this.path[i][1] - this.bounds.right;
			}
			if(y < this.bounds.top) {
				diff.y = this.path[i][2] - this.bounds.top;
			}
			if(y > this.bounds.bottom) {
				diff.y = this.path[i][2] - this.bounds.bottom;
			}
		}
		if(diff.x !== 0) {
			this.lastDrag.x = this.pointer.x;
		}
		if(diff.y !== 0) {
			this.lastDrag.y = this.pointer.y;
		}
		return diff;
	},
	
	isWithinBounds: function(p) {
		if(p.x < this.bounds.left || p.x > this.bounds.right || p.y < this.bounds.top || p.y > this.bounds.bottom) {
			return false;
		}
		return true;
	},
	
	areWithinBounds: function(points) {
		for(var i in points) {
			if(!this.isWithinBounds(points[i])) {
				return false;
			}
		}
		return true;
	},
	
	stopDrag: function() {
		// stop dragging the path
		if(this.state === ToolStates.dragging) {
			this.getBoundingBox();
			return this.state = ToolStates.complete;
		}
	},
	
	dragPoints: function(diff) {
		for(var i in this.points) {
			var p = this.points[i];
			p.setLeft(p.left - diff.x);
			p.setTop(p.top - diff.y);
		}
	},
	
	animateDash: function() {
		window.cancelAnimationFrame(lineDashAnim);
		activeTool.offset += 0.5;
		if(activeTool.offset > 16) {
			activeTool.offset = 0;
		}
		veneerDesigner.ctx.lineDashOffset = -activeTool.offset;
		veneerDesigner.canvas.renderAll();
		lineDashAnim = window.requestAnimationFrame(activeTool.animateDash);
	},
	
	getSelectedPixels: function() {
		if(this.dimensionsChanged === false) {
			return this.selectedPixels;
		}
		
		this.selectedPixels = [];
		
		// make sure the active path is the selection
		this.reconstructPath();
		
		for(var w = this.poi.left.x; w <= this.poi.right.x; w++) {
			this.selectedPixels[w] = [];
			for(var h = this.poi.top.y; h <= this.poi.bottom.y; h++) {
				if(veneerDesigner.ctx.isPointInPath(w, h, 'nonzero')) {
					var i = 4*(h*veneerDesigner.canvas.width + w);
					this.selectedPixels[w][h] = [veneerDesigner.data[i],veneerDesigner.data[i+1],veneerDesigner.data[i+2],veneerDesigner.data[i+3]];
				}
			}
		}
		
		this.dimensionsChanged = false;
		
		return this.selectedPixels;
	},
	
	cut: function() {
		var selectedPixels = this.getSelectedPixels(),
		poi = this.getPoi();
	
		var width = Math.round(poi.right.x - poi.left.x),
		height = Math.round(poi.bottom.y - poi.top.y),
		canvasEl = fabric.util.createCanvasElement(),
		ctx = canvasEl.getContext('2d');
	
		canvasEl.width = width;
		canvasEl.height = height;
		
		var cutImagedata = ctx.getImageData(0, 0, width, height),
		cutdata = cutImagedata.data;	
	
		for(var w = 0; w < width; w++) {
			for(var h = 0; h < height; h++) {
				var i = 4*(h*width + w),
				x = w + poi.left.x,
				y = h + poi.top.y;
				
				if(typeof selectedPixels[x] === "undefined" || typeof selectedPixels[x][y] === "undefined") {
					cutdata[i] = 255;
					cutdata[i+1] = 255;
					cutdata[i+2] = 255;
					cutdata[i+3] = 0;
				} else {
					cutdata[i] = selectedPixels[x][y][0];
					cutdata[i+1] = selectedPixels[x][y][1];
					cutdata[i+2] = selectedPixels[x][y][2];
					cutdata[i+3] = selectedPixels[x][y][3];
				}
			}
		}
		
		ctx.putImageData(cutImagedata, 0, 0);
		var dataurl = canvasEl.toDataURL();
		
		canvasEl = null;
		veneerDesigner.canvas.remove(activeTool);
		veneerDesigner.setDrag(true);
		return dataurl;
	},
	
	reconstructPath: function() {
		this.remove();
		veneerDesigner.canvas.add(this);
	},
	
	getCorners: function() {
		return this.corners;
	},
	
	getPoi: function() {
		return this.poi;
	},
	
	newTool: function(ev) {
		this.destroy();
		startTool(ev);
	},
	
	destroy: function() {
		veneerDesigner.canvas.off('mouse:move');
		veneerDesigner.canvas.off('mouse:down');
		veneerDesigner.canvas.off('mouse:up');
		
		this.removePoints();
		
		this.remove();
		this.clearBoundingBox();
		activeTool = null;
		window.cancelAnimationFrame(lineDashAnim);
	},
	
	removePoints: function() {
		for(var i in this.points) {
			this.points[i].remove();
		}
		this.points.length = 0;
		this.points = [];
	},
	
	getSlope: function(p1, p2) {
		return (p1.y - p2.y) / (p1.x - p2.x);
	},
	
	getYIntercept: function(p, m) {
		return p.y - m * p.x;
	},
	
	findPointOnLineByDistance: function(p, m, c, d) {
		// finden der Punkte mit Distanz d von p2 auf der Gerade ( x = x0 +- d / sqrt(1+m²) )
		var x0 = p.x + d / Math.sqrt(1+m*m)
		// y = m*x+c
		return new fabric.Point(x0, m*x0+c);
	},
	
	showPoints: function() {
		this.removePoints();
		
		for(var i = 0; i < this.path.length; i++) {
			if(typeof this.skipPoints !== "undefined" && $.inArray(i, this.skipPoints) > -1) {
				continue;
			}
			if(this.path[i][0] === 'Z') {
				continue;
			}
			
			var x = this.path[i][1],
			y = this.path[i][2];
			
			this.showPoint(x, y);
			
			if(this.path[i][0] === 'Q') {
				var x2 = this.path[i][3],
				y2 = this.path[i][4];
				this.showPoint(x2, y2);
			}
		}
		
		this.reconstructPath();
	},
	
	showPoint: function(x, y) {
		var stroke = this.getPointColor();
		
		var rect = new fabric.Rect({
				width: pointSize,
				height: pointSize,
				fill: '',
				stroke: stroke,
				opacity: 0.7,
				selectable: false,
				left: x - pointSize/2,
				top: y - pointSize/2
			}
		);
		
		this.points.push(rect);
		veneerDesigner.canvas.add(rect);
	},
	
	getPointColor: function() {
		return pointColor;
	},
	
	extendBoundingBox: function(p) {
		var x = Math.round(p.x),
		y = Math.round(p.y);
		
		if(typeof this.poi.top === "undefined" || this.poi.top.y > y) {
			this.poi.top = {x: x, y: y};
		}
		if(typeof this.poi.right === "undefined" || this.poi.right.x < x) {
			this.poi.right = {x: x, y: y};
		}
		if(typeof this.poi.bottom === "undefined" || this.poi.bottom.y < y) {
			this.poi.bottom = {x: x, y: y};
		}
		if(typeof this.poi.left === "undefined" || this.poi.left.x > x) {
			this.poi.left = {x: x, y: y};
		}
		if(typeof this.corners.tl === "undefined" || (this.corners.tl.y >= y && this.corners.tl.x >= x)) {
			this.corners.tl = {x: x, y: y, corner: 'tl'};
		}
		if(typeof this.corners.tr === "undefined" || (this.corners.tr.y >= y && this.corners.tr.x <= x)) {
			this.corners.tr = {x: x, y: y, corner: 'tr'};
		}
		if(typeof this.corners.br === "undefined" || (this.corners.br.y <= y && this.corners.br.x <= x)) {
			this.corners.br = {x: x, y: y, corner: 'br'};
		}
		if(typeof this.corners.bl === "undefined" || (this.corners.bl.y <= y && this.corners.bl.x >= x)) {
			this.corners.bl = {x: x, y: y, corner: 'bl'};
		}
	},
	
	clearBoundingBox: function() {
		this.poi = [];
		this.corners = [];
		if(typeof this._proto__ !== "undefined") {
			this.__proto__.__proto__.poi = [];
			this.__proto__.__proto__.corners = [];
		}
	},
	
	getBoundingBox: function() {
		this.clearBoundingBox();
		for(var i = 0; i < this.path.length-1; i++) {
			var p = new fabric.Point(this.path[i][1], this.path[i][2]);
			this.extendBoundingBox(p);
		}
	},
	
	isCloseToPoint: function(p) {
		var j = 0;
		for(var i = 0; i < this.path.length-1; i++) {
			if(typeof this.skipPoints !== "undefined" && $.inArray(i, this.skipPoints) > -1) {
				continue;
			}
			var point = new fabric.Point(this.path[i][1], this.path[i][2]);
			if(point.distanceFrom(p) < resizeDistance) {
				return j;
			}
			j++;
		}
		return false;
	},
	
	resetPointColors: function() {
		for(var i in this.points) {
			this.points[i].set('stroke', pointColor);
		}
	},
	
	checkPointHover: function() {
		var p = new fabric.Point(this.pointer.x, this.pointer.y);
		var point = this.isCloseToPoint(p);
		if(point !== false) {
			this.points[point].set('stroke', pointHoverColor);
		} else {
			this.resetPointColors();
		}
	},
	
	toString: function() {
		return this.callSuper('toString');
	},
	
	// start the tool after initializing without resizing
	staticStart: function() {
		this.animateDash();
		this.showPoints();
		this.reconstructPath();
		this.getBoundingBox();
	},
});

var Lasso = fabric.util.createClass(CutTool, {
	setPathPoints: 0,
	
	initialize: function(x, y) {
		// second point has to be set, otherwise nothing works (...)
		this.callSuper('initialize', [['M', x, y], ['L', x+1, y+1]], pathOptions);
		this.showPoint(x, y);
		this.setPathPoints = 1;
	},
	
	onMouseDown: function(ev) {
		this.callSuper('onMouseDown', ev);
		if(this.state === ToolStates.incomplete) {
			this.setPath();
		}
	},
	
	onTouchEnd: function(ev) {
		this.callSuper('onTouchEnd', ev);
		if(this.state === ToolStates.incomplete) {
			this.setPath();
		}
	},
	
	isCloseToStart: function() {
		return (Math.abs(this.pointer.x - this.path[0][1]) < endDistance && Math.abs(this.pointer.y - this.path[0][2]) < endDistance);
	},
	
	checkStartButtonHover: function() {
		if(this.isCloseToStart()) {
			this.points[0].set('stroke', pointHoverColor);
		} else {
			this.points[0].set('stroke', specialPointColor);
		}
	},
	
	previewPath: function() {
		this.path[this.path.length-1] = ['L', this.pointer.x, this.pointer.y];
		veneerDesigner.canvas.renderAll();
	},
	
	setPath: function() {
		this.previewPath();
		if(this.isCloseToStart()) {
			if(this.path.length <= 2) {
				return;
			}
			var p = new fabric.Point(this.path[0][1], this.path[0][2]);
			this.endPath();
		} else {
			var p = new fabric.Point(this.pointer.x, this.pointer.y);
			this.path.push(['L', p.x, p.y]);
			this.setPathPoints++;
			this.showPoint(p.x, p.y);
		}
		this.extendBoundingBox(p);
		veneerDesigner.canvas.renderAll();
	},
	
	endPath: function() {
		if(this.path.length > this.setPathPoints) {
			this.path.pop();
		}
		this.path.push(['Z']);
		this.animateDash();
		this.state = ToolStates.complete;
		this.removePoints();
	},
	
	getPointColor: function() {
		if(this.points.length === 0) {
			return specialPointColor;
		}
		return pointColor;
	},
});

var LassoRound = fabric.util.createClass(Lasso, {
	setPathPoints: 0,
	curve: 0,
	
	initialize: function(x, y, curve) {
		this.curve = curve;
		this.callSuper('initialize', x, y);
	},
	
	roundEdges: function() {
		// neuer Pfad
		var tempPath = [['M', this.path[0][1], this.path[0][2]]];
		// alle Ecken abrunden
		for(var i = 1; i < this.path.length; i++) {
			// alle relevanten Punkte
			var p1 = new fabric.Point(this.path[i-1][1], this.path[i-1][2]),
			p2 = new fabric.Point(this.path[i][1], this.path[i][2]);
			
			// an der letzten Ecke muss der Punkt der zweiten Ecke verwendet werden
			if(i === this.path.length-1) {
				p3 = new fabric.Point(this.path[1][1], this.path[1][2]);
			} else {
				p3 = new fabric.Point(this.path[i+1][1], this.path[i+1][2]);
			}
			
			// distanz der eckpunkte
			var d1 = p2.distanceFrom(p1),
			d2 = p3.distanceFrom(p2)
			// kurvenwerte für beide Seiten
			curve1 = this.curve,
			curve2 = this.curve;
			
			// falls die Distanz der Eckpunte zu gering ist muss der kurvenwert verringert werden um Kanten zu vermeiden
			if(curve1 > d1/2) {
				curve1 = d1/2;
			}
			if(curve2 > d2/2) {
				curve2 = d2/2;
			}
			
			// beide Geraden aufstellen
			var m12 = this.getSlope(p1, p2),
			m23 = this.getSlope(p2, p3),
			c12 = this.getYIntercept(p1, m12),
			c23 = this.getYIntercept(p2, m23);
			
			// den korrekten Punkt zum erstellen der Kurve verwenden
			if(p1.x > p2.x) {
				var cp1 = this.findPointOnLineByDistance(p2, m12, c12, curve1);
			} else {
				var cp1 = this.findPointOnLineByDistance(p2, m12, c12, -curve1);
			}
			if(p2.x < p3.x) {
				var cp2 = this.findPointOnLineByDistance(p2, m23, c23, curve2);
			} else {
				var cp2 = this.findPointOnLineByDistance(p2, m23, c23, -curve2);
			}
			
			if(p1.x === p2.x) {
				if(p2.y > p1.y) {
					var cp1 = new fabric.Point(p2.x, p2.y - curve1);
				} else {
					var cp1 = new fabric.Point(p2.x, p2.y + parseFloat(curve1));
				}
			}
			if(p2.x === p3.x) {
				if(p2.y > p3.y) {
					var cp2 = new fabric.Point(p2.x, p2.y - curve2);
				} else {
					var cp2 = new fabric.Point(p2.x, p2.y + parseFloat(curve2));
				}
			}
			
			// neue Punkte einfügen
			tempPath.push(['L', cp1.x, cp1.y]);
			tempPath.push(['Q', p2.x, p2.y, cp2.x, cp2.y]);
			
			if(i === this.path.length-1) {
				tempPath[0] = ['M', cp2.x, cp2.y];
			}
		}
		return tempPath;
	},
	
	drag: function() {
		var diff = {x: this.lastDrag.x - this.pointer.x, y: this.lastDrag.y - this.pointer.y};
		this.lastDrag = {x: this.pointer.x, y: this.pointer.y};
		
		for(var i = 0; i < this.path.length; i++) {
			var sub = this.path[i];
			
			if(sub[0] === 'L' || sub[0] === 'M') {
				var x = this.path[i][1] - diff.x,
				y = this.path[i][2] - diff.y;
				
				this.path[i] = ['L', x, y];
			} else {
				var x0 = this.path[i][1] - diff.x,
				y0 = this.path[i][2] - diff.y,
				x1 = this.path[i][3] - diff.x,
				y1 = this.path[i][4] - diff.y;
				
				this.path[i] = ['Q', x0, y0, x1, y1];
			}
		}
		
		veneerDesigner.canvas.renderAll();
	},
	
	endPath: function() {
		this.path.pop();
		this.path.push(['L', this.path[0][1], this.path[0][2]]);
		
		this.path = this.roundEdges();
		
		this.animateDash();
		this.state = ToolStates.complete;
		this.removePoints();
	},
	
	getPointColor: function() {
		if(this.points.length === 0) {
			return specialPointColor;
		}
		return pointColor;
	},
});

var CutFree = fabric.util.createClass(CutTool, {	
	initialize: function(x, y) {
		this.callSuper('initialize', [['M', x, y], ['L', x+1, y+1]], pathOptions);
	},
	
	onMove: function(ev) {
		// show a preview before setting a subpath
		if(this.state === ToolStates.incomplete) {
			return this.drawPath();
		}
		// drag the complete path around
		if(this.state === ToolStates.dragging) {
			return this.drag();
		}
	},
	
	onMouseUp: function(ev) {
		this.callSuper('onMouseUp', ev);
		if(this.state === ToolStates.incomplete) {
			this.state = ToolStates.complete;
			return this.endPath();
		}
	},
	
	onTouchEnd: function(ev) {
		this.callSuper('onTouchEnd', ev);
		if(this.state === ToolStates.incomplete) {
			this.state = ToolStates.complete;
			return this.endPath();
		}
	},
	
	drawPath: function() {
		var p = new fabric.Point(this.pointer.x, this.pointer.y);
		this.path.push(['L', p.x, p.y]);
		this.extendBoundingBox(p);
		veneerDesigner.canvas.renderAll();
	},
	
	endPath: function() {
		var p = new fabric.Point(this.path[0][1], this.path[0][2]);
		this.extendBoundingBox(p);
		this.path.push(['Z']);
		this.animateDash();
		this.state = ToolStates.complete;
		veneerDesigner.canvas.renderAll();
	},
});

var CutRect = fabric.util.createClass(CutTool, {
	state: ToolStates.complete,
	//resizeCorner: 2,
	
	initialize: function(x, y, size, path) {
		if(path === undefined) {
			var path = [['M', x, y], ['L', x+size, y], ['L', x+size, y+size], ['L', x, y+size], ['Z']];
		}
		this.callSuper('initialize', path, pathOptions);
	},
	
	onMove: function(ev) {
		if(this.state === ToolStates.complete) {
			this.checkPointHover();
		}
		if(this.state === ToolStates.resizing) {
			this.removePoints();
			return this.resize();
		}
		// drag the complete path around
		if(this.state === ToolStates.dragging) {
			return this.drag();
		}
	},
	
	onMouseDown: function(ev) {
		if(this.state === ToolStates.complete) {
			var corner = this.isCloseToPoint(new fabric.Point(this.pointer.x, this.pointer.y));
			if(corner !== false) {
				window.cancelAnimationFrame(lineDashAnim);
				this.resizeCorner = corner;
				this.state = ToolStates.resizing;
			} else {
				this.callSuper('onMouseDown', ev);
			}
		}
	},
	
	onMouseUp: function(ev) {
		// stop dragging the path
		if(this.state === ToolStates.dragging || this.state === ToolStates.resizing) {
			this.animateDash();
			this.showPoints();
			this.reconstructPath();
			this.getBoundingBox();
			return this.state = ToolStates.complete;
		}
	},
	
	onTouchStart: function(ev) {
		this.onMouseDown(ev);
	},
	
	onTouchEnd: function(ev) {
		this.onMouseUp(ev);
	},
	
	resize: function() {
		this.dimensionsChanged = true;
		var x = this.pointer.x,
		y = this.pointer.y,
		corners = [this.resizeCorner - 1, this.resizeCorner + 1, this.resizeCorner + 2];
		
		for(var i in corners) {
			if(corners[i] === -1) {corners[i] = 3};
			if(corners[i] === 4) {corners[i] = 0};
			if(corners[i] === 5) {corners[i] = 1};
		}
		
		var c0 = new fabric.Point(this.path[this.resizeCorner][1], this.path[this.resizeCorner][2]),
		c1 = new fabric.Point(this.path[corners[0]][1], this.path[corners[0]][2]),
		c2 = new fabric.Point(this.path[corners[1]][1], this.path[corners[1]][2]);
		
		if(this.shiftKey || this.shiftKeyOverride) {
			// ecken umdrehen
			if(c0.y === c1.y) {
				var temp = c1;
				c1 = c2;
				c2 = temp;
			}
			// distanz der Ecken
			var xDist = Math.abs(x - c2.x),
			yDist = Math.abs(y - c1.y),
			dx = 0,
			dy = 0;
			
			// 1.Quadrant
			if(x > c2.x && y > c1.y) {
				dx = yDist - xDist;
				dy = xDist - yDist;
			// 2.Quadrant
			} else if(x < c2.x && y > c1.y) {
				dx = -1 * (yDist - xDist);
				dy = xDist - yDist;
			// 3.Quadrant
			} else if(y < c1.y && x > c2.x) {
				dx = yDist - xDist;
				dy = -1 * (xDist - yDist);
			// 4.Quadrant
			} else if(y < c1.y && x < c2.x) {
				dx = -1 * (yDist - xDist);
				dy = -1 * (xDist - yDist);
			}
			
			// zu veraenderten Wert waehlen
			if(xDist < yDist) {
				x += dx;
			} else {
				y += dy;
			}
		}
		
		for(var i = 0; i < corners.length-1; i++) {
			var c = corners[i];
			
			// prevent funky behaviour when points are the same
			if(this.path[corners[0]][1] === this.path[corners[1]][1] || this.path[corners[0]][2] === this.path[corners[1]][2]) {
				this.path[corners[0]][1] = x;
				this.path[corners[0]][2] = this.path[corners[2]][2];
				this.path[corners[1]][1] = this.path[corners[2]][1];
				this.path[corners[1]][2] = y;
				break;
			}
			
			if(this.path[c][1] === c0.x) {
				this.path[c][1] = x;
			} else {
				this.path[c][2] = y;
			}
		}
		
		this.path[this.resizeCorner][1] = x;
		this.path[this.resizeCorner][2] = y;
		
		veneerDesigner.canvas.renderAll();
	},
});

var CutStaticSquare = fabric.util.createClass(CutRect, {
	state: ToolStates.complete,
	
});

var CutDiamond = fabric.util.createClass(CutRect, {
	state: ToolStates.complete,
	skipPoints: [0, 1, 2],
	
	initialize: function(x, y, size) {
		var path = [['M', x, y+size/2], ['L', x+size/2, y], ['L', x+size, y+size/2], ['L', x+size/2, y+size], ['Z']];
		this.callSuper('initialize', x, y, size, path);
	},
	
	resize: function() {
		this.dimensionsChanged = true;
		var x = this.pointer.x,
		y = this.pointer.y,	
		width = (x - this.path[0][1]) * 2;
		
		var points = [
			new fabric.Point(x - width/2, y - width/2),
			new fabric.Point(x, y - width),
			new fabric.Point(x + width/2, y - width/2),
			new fabric.Point(x, y)
		];
		
		if(this.areWithinBounds(points)) {
			for(var i = 0; i < this.path.length-1; i++) {
				this.path[i][1] = points[i].x;
				this.path[i][2] = points[i].y;
			}

			veneerDesigner.canvas.renderAll();
		}
	},
});

var CutCircle = fabric.util.createClass(CutTool, {
	state: ToolStates.resizing,
	center: {},
	resizePoint: 2,
	radius: {x: 1, y: 1},
	fp: {},
	
	initialize: function(x, y) {
		var size = 1;
		this.callSuper('initialize', [['M', x, y], ['A', size, size, 0, 1, 1, x+size, y], ['A', size, size, 0, 1, 1, x-size, y]], pathOptions);
		this.center = new fabric.Point(x, y);
		var points = this.getPoints();
		this.fp = this.getFurthestPointFrom(points[this.resizePoint], points);
	},
	
	onMove: function(ev) {
		if(this.state === ToolStates.complete) {
			return this.checkPointHover();
		}
		if(this.state === ToolStates.resizing) {
			return this.resize(ev);
		}
		// drag the complete path around
		if(this.state === ToolStates.dragging) {
			return this.drag();
		}
	},
	
	onMouseDown: function(ev) {
		var p = new fabric.Point(this.pointer.x, this.pointer.y),
		point = this.isCloseToPoint(p),
		points = this.getPoints();
		window.cancelAnimationFrame(lineDashAnim);
		if(point !== false) {
			this.state = ToolStates.resizing;
			this.fp = this.getFurthestPointFrom(points[point], points);
			this.resizePoint = point;
			return this.removePoints();
		}
		if(this.state === ToolStates.complete) {
			this.removePoints();
			this.callSuper('onMouseDown', ev);
		}
	},
	
	onMouseUp: function() {
		// stop dragging the path
		if(this.state === ToolStates.dragging || this.state === ToolStates.resizing) {
			this.animateDash();
			this.getBoundingBox();
			this.showPoints();
			return this.state = ToolStates.complete;
		}
	},
	
	onTouchStart: function(ev) {
		this.onMouseDown(ev);
	},
	
	onTouchEnd: function(ev) {
		this.onMouseUp(ev);
	},
	
	resize: function(ev) {
		this.dimensionsChanged = true;
		
		var x = this.pointer.x,
		y = this.pointer.y,
		p = new fabric.Point(x, y),
		points = this.getPoints();
		points[this.resizePoint].x = x;
		points[this.resizePoint].y = y;
		
		this.radius.x = Math.round((p.x - this.fp.x)/2);
		this.radius.y = Math.round((p.y - this.fp.y)/2);
		
		this.center.x = this.fp.x + this.radius.x;
		this.center.y = this.fp.y + this.radius.y;
			
		if(this.shiftKey) {
			if(this.radius.x > this.radius.y) {
				this.radius.y = this.radius.x;
			} else {
				this.radius.x = this.radius.y;
			}
			
			this.center.x = this.fp.x + this.radius.x;
			this.center.y = this.fp.y + this.radius.y;
			
			if(points[this.resizePoint].x < this.fp.x && points[this.resizePoint].y > this.fp.y) {
				this.center.x = this.fp.x - this.radius.x;
			}
			if(points[this.resizePoint].x > this.fp.x && points[this.resizePoint].y < this.fp.y) {
				this.center.y = this.fp.y - this.radius.y;
			}
		}
		
		this.setCirclePath();
		
		veneerDesigner.canvas.renderAll();
	},
	
	getFurthestPointFrom: function(p, points) {
		var fp,
		fdist = 0;
		for(var i in points) {
			var dist = p.distanceFrom(points[i]);
			if(dist > fdist) {
				fdist = dist;
				fp = points[i];
			}
		}
		return fp;
	},
	
	drag: function() {
		var diff = {x: this.lastDrag.x - this.pointer.x, y: this.lastDrag.y - this.pointer.y};
		this.lastDrag = {x: this.pointer.x, y: this.pointer.y};
		
		this.center.x -= diff.x;
		this.center.y -= diff.y;
		this.setCirclePath();
		
		veneerDesigner.canvas.renderAll();
	},
	
	setCirclePath: function() {
		this.path[0][1] = this.center.x - this.radius.x;
		this.path[0][2] = this.center.y;
		this.path[1][1] = this.radius.x;
		this.path[1][2] = this.radius.y;
		this.path[1][6] = this.center.x + this.radius.x;
		this.path[1][7] = this.center.y;
		this.path[2][1] = this.radius.x;
		this.path[2][2] = this.radius.y;
		this.path[2][6] = this.center.x - this.radius.x;
		this.path[2][7] = this.center.y;
	},
	
	showPoints: function() {
		this.removePoints();
		var points = this.getPoints();
		
		for(var i in points) {
			var p = points[i];
			this.showPoint(p.x, p.y);
		}
			
		this.reconstructPath();
	},
	
	isCloseToPoint: function(p) {
		var points = this.getPoints();
		
		for(var i = 0; i < points.length; i++) {
			if(points[i].distanceFrom(p) < resizeDistance) {
				return i;
			}
		}
		return false;
	},
	
	getPoints: function() {
		return [
			new fabric.Point(this.path[0][1], this.path[0][2] - this.radius.y),
			new fabric.Point(this.path[0][1], this.path[0][2] + this.radius.y),
			new fabric.Point(this.path[0][1] + 2 * this.radius.x, this.path[0][2] - this.radius.y),
			new fabric.Point(this.path[0][1] + 2 * this.radius.x, this.path[0][2] + this.radius.y),
		];
	},
	
	getBoundingBox: function() {
		this.clearBoundingBox();
		
		var points = this.getPoints();
		
		for(var i in points) {
			p = points[i];
			this.extendBoundingBox(p);
		}
	},
});

var CutTriangle = fabric.util.createClass(CutTool, {
	state: ToolStates.complete,
	resizePoint: 1,
	
	initialize: function(x, y, path, size) {
		if(path === undefined || path === null) {
			path = [['M', x-size, y+size], ['L', x+size, y+size], ['L', x, y-size], ['Z']];
		}
		this.callSuper('initialize', path, pathOptions);
	},
	
	beforeEvent: function(ev) {
		if(this.state === ToolStates.incomplete) {
			this.state = ToolStates.complete;
			this.animateDash();
			this.getBoundingBox();
			this.showPoints();
		}
		return this.callSuper('beforeEvent', ev);
	},
	
	onMove: function(ev) {
		if(this.state === ToolStates.complete) {
			return this.checkPointHover();
		}
		// drag the complete path around
		if(this.state === ToolStates.dragging) {
			return this.drag();
		}
		if(this.state === ToolStates.resizing) {
			return this.resize();
		}
	},
	
	onMouseDown: function(ev) {
		if(this.state === ToolStates.complete) {
			var point = this.isCloseToPoint(new fabric.Point(this.pointer.x, this.pointer.y));
			window.cancelAnimationFrame(lineDashAnim);
			if(point !== false) {
				this.state = ToolStates.resizing;
				this.resizePoint = point;
				return this.removePoints();
			}
			this.callSuper('onMouseDown', ev);
		}
	},
	
	onMouseUp: function(ev) {
		// stop dragging the path
		if(this.state === ToolStates.dragging) {
			this.animateDash();
			this.getBoundingBox();
			return this.state = ToolStates.complete;
		}
		if(this.state === ToolStates.resizing) {
			this.initialResize = false;
			this.state = ToolStates.complete;
			this.animateDash();
			this.getBoundingBox();
			this.showPoints();
		}
	},
	
	onTouchStart: function(ev) {
		this.onMouseDown(ev);
	},
	
	onTouchEnd: function(ev) {
		this.onMouseUp(ev);
	},
	
	resize: function() {
		this.dimensionsChanged = true;
		
		if(this.shiftKey || this.initialResize) {
			var diffX = this.pointer.x - this.path[this.resizePoint][1],
			diffY = this.pointer.y - this.path[this.resizePoint][2];
			
			if(this.resizePoint === 0) {
				this.path[1][1] -= diffX;
				this.path[1][2] += diffY;
				this.path[2][2] -= diffY;
			}
			if(this.resizePoint === 1) {
				this.path[0][1] -= diffX;
				this.path[0][2] += diffY;
				this.path[2][2] -= diffY;
			}
		}
		
		this.path[this.resizePoint][1] = this.pointer.x;
		this.path[this.resizePoint][2] = this.pointer.y;
		
		veneerDesigner.canvas.renderAll();
	},
});

var CutStaticVerticalTriangle = fabric.util.createClass(CutTriangle, {
	state: ToolStates.complete,
	skipPoints: [0, 2],
	
	initialize: function(x, y, height) {
		height = Math.min(height, veneerDesigner.canvas.height/2);
		var sidelength = Math.round(height * Math.sqrt(2)),
		c = Math.round(Math.sqrt(sidelength*sidelength+sidelength*sidelength));
		var path = [['M', x, y+c/2], ['L', x+height, y+c], ['L', x+height, y], ['Z']];
		this.callSuper('initialize', x, y, path);
	},
	
	resize: function() {
		this.dimensionsChanged = true;
		var x = this.pointer.x,
		y = this.pointer.y,
		height = x - this.path[0][1],
		sidelength = Math.round(height * Math.sqrt(2)),
		c = Math.round(Math.sqrt(sidelength*sidelength+sidelength*sidelength));
	
		var points = [
			new fabric.Point(x - height, y - c/2),
			new fabric.Point(x, y),
			new fabric.Point(x, y - c)
		];
		
		if(this.areWithinBounds(points)) {
			for(var i = 0; i < this.path.length-1; i++) {
				this.path[i][1] = points[i].x;
				this.path[i][2] = points[i].y;
			}

			veneerDesigner.canvas.renderAll();
		}
	},
});

var CutStaticHorizontalTriangle = fabric.util.createClass(CutTriangle, {
	state: ToolStates.complete,
	skipPoints: [0, 1],
	
	initialize: function(x, y, width) {
		
		var c = width,
		p = c/2,
		h = Math.round(Math.sqrt(p*p));
		var path = [['M', x+c/2, y], ['L', x, y+h], ['L', x+c, y+h], ['Z']];
		this.callSuper('initialize', x, y, path);
	},
	
	resize: function() {
		this.dimensionsChanged = true;
		var x = this.pointer.x,
		y = this.pointer.y,
		c = x - this.path[1][1],
		p = c/2,
		h = Math.round(Math.sqrt(p*p));
	
		var points = [
			new fabric.Point(x - p, y - h),
			new fabric.Point(x - c, y),
			new fabric.Point(x, y)
		];
		
		if(this.areWithinBounds(points)) {
			for(var i = 0; i < this.path.length-1; i++) {
				this.path[i][1] = points[i].x;
				this.path[i][2] = points[i].y;
			}

			veneerDesigner.canvas.renderAll();
		}
	},
});

var CutStaticRightTriangle = fabric.util.createClass(CutTriangle, {
	state: ToolStates.complete,
	skipPoints: [0, 1],
	
	initialize: function(x, y, width, height) {
		
		var a = (height / Math.sin(fabric.util.degreesToRadians(67.5)) * Math.sin(fabric.util.degreesToRadians(22.5)));
		if(a > width) {
			a = width;
			height = (width / Math.sin(fabric.util.degreesToRadians(22.5)) * Math.sin(fabric.util.degreesToRadians(67.5)));
		}
		var path = [['M', x+a, y], ['L', x, y+height], ['L', x+a, y+height], ['Z']];
		this.callSuper('initialize', x, y, path);
	},
	
	resize: function() {
		this.dimensionsChanged = true;
		var x = this.pointer.x,
		y = this.pointer.y,
		width = x - this.path[1][1],
		height = (width / Math.sin(fabric.util.degreesToRadians(22.5)) * Math.sin(fabric.util.degreesToRadians(67.5)));
		
		/*
		if(height > 900) {
			return;
		}
		*/
		
		var points = [
			new fabric.Point(x, y - height),
			new fabric.Point(this.path[1][1], y),
			new fabric.Point(x, y)
		];
		
		if(this.areWithinBounds(points)) {
			for(var i = 0; i < this.path.length-1; i++) {
				this.path[i][1] = points[i].x;
				this.path[i][2] = points[i].y;
			}

			veneerDesigner.canvas.renderAll();
		}
	},
});

/*
VeneerDesigner.canvas.on({
	'mouse:down': startTool,
	'touch:drag': startTool
});
*/

var radios = document.getElementsByName('tool');
for(var i = 0; i < radios.length; i++) {
	radios[i].addEventListener('click', function(event) {
		if(typeof activeTool !== "undefined" && activeTool !== null && chosenTool !== parseInt(event.target.value)) {
			activeTool.destroy();
			veneerDesigner.canvas.on({
				'mouse:down': startTool,
				'touch:drag': startTool
			});
		}
		chosenTool = parseInt(event.target.value);
	});
}
