function colorPreview(options) {

	this.container = null;
	this.otherContainer = null;

	this.side = options.side;
	this.color = options.color;
	this.isSecondaryPreview = options.isSecondaryPreview;
	this.canvas = options.canvas;
	this.ctx = options.canvas.getContext();

	this.preview = null;
	this.materialPreview = null;
	this.otherMaterialPreview = null;
	this.materialList = null;
	this.otherMaterialList = null;

	this.visible = false;

	this.init = function() {
		this.container = $(options.containerId);
		this.otherContainer = $(options.otherContainerId);
		this.picker = $(options.pickerId);
		this.otherPicker = $(options.otherPickerId);
		this.materialPreview = $(options.materialPreviewId);
		this.otherMaterialPreview = $(options.otherMaterialPreviewId);
		this.materialList = this.container.find('.materialList li');
		this.otherMaterialList = this.otherContainer.find('.materialList li');

		this.initializePicker();
	};

	this.initializePicker = function() {
		var self = this;

		this.picker.colpick({
			flat: true,
			appendTo: this.container.find('.colorPicker')[0],
			layout: 'rgbhex',
			submit: 0,
			colorScheme: 'dark',
			color: self.color,
			onChange: $.proxy(self.onChange, self),
			height: 275
		}).keyup(function() {
			$(this).colpickSetColor(this.value);
		});

		this.picker.val(self.color);
		this.picker.css('border-color', self.color);

		this.picker.on('click', $.proxy(this.show, this));
	};

	this.onChange = function(hsb, hex, rgb, el, bySetColor) {
		$(el).parent().toggleClass('lightPreviewColor', (rgb.r + rgb.g + rgb.b > 400));
		this.removePattern(true);
		// reset material on color change
		this.materialList.removeClass('act');
		Renderer.setMaterial(this.side, null);

		$(el).css('border-color', '#' + hex);
		// Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
		if(!bySetColor) {
			$(el).val('#' + hex);
		}

		if(Renderer.getColorPreviewType() === ColorPreviewTypes.UNIFIED) {
			this.otherPicker.css('border-color', '#' + hex);
			// reset other material
			this.otherMaterialList.removeClass('act');
		}

		if(this.isSecondaryPreview && Renderer.getColorPreviewType() === ColorPreviewTypes.UNIFIED) {
			this.otherPicker.colpickSetColor('#' + hex);
		} else {
			this.color = '#' + hex;
			this.preview.set('fill', this.color);
			this.preview.render(this.ctx);
			this.preview.sendToBack();
		}
	};

	this.show = function() {
		if(this.visible === true) {
			this.container.hide();
			$(document).off('click.colpick' + this.side);
			this.visible = false;
			return;
		}

		this.container.show();
		$(document).on('click.colpick' + this.side, $.proxy(this.hide, this));
		this.visible = true;
	};

	this.hide = function(event) {
		if(!$(event.target).closest(options.containerId).length && !$(event.target).is(this.picker)) {
			this.container.hide();
			$(document).off('click.colpick' + this.side);
			this.visible = false;
		}
	}

	this.render = function(pos, width, height) {
		this.preview = new fabric.Rect({left: pos.left, top: pos.top, width: width, height: height, fill: this.color, selectable: false, hasBorders: false, evented: false});
		this.canvas.add(this.preview);
	};

	this.setPattern = function(img) {
		if(this.preview !== null) {
			this.preview.fill = new fabric.Pattern({
				source: img,
				repeat: 'repeat'
			});
		}

		this.materialPreview.css('background-image', 'url(' + img.src + ')');
		if(Renderer.getColorPreviewType() === ColorPreviewTypes.UNIFIED) {
			this.otherMaterialPreview.css('background-image', 'url(' + img.src + ')');
		}
	};

	this.removePattern = function(setOtherPreview) {
		this.materialPreview.css('background-image', '');

		if(Renderer.getColorPreviewType() === ColorPreviewTypes.UNIFIED && setOtherPreview === true) {
			this.otherMaterialPreview.css('background-image', '');
		}
	};

	this.init();
}