var CutModule = (function () {

	/**
	 * Check if the image requires a cut in its configuration
	 * @returns {Boolean}
	 */
	function cutRequired() {
		var cutRequired = getConfig().requireCut;
		if (typeof cutRequired !== "undefined") {
			hideIntarsias();
			updateImagedata();
			if (cutRequired === 'diamond') {
				return _cutRequiredDiamond();
			}
			// unsused
			if (cutRequired === 'square') {
				return _cutRequiredSquare();
			}
			if (cutRequired === 'horizontal_triangle') {
				return _cutRequiredHorizontalTriangle();
			}
			if (cutRequired === 'vertical_triangle') {
				return _cutRequiredVerticalTriangle();
			}
			if (cutRequired === 'right_triangle') {
				return _cutRequiredRightTriangle();
			}
		}
		return false;
	}

	/**
	 * Cut a diamond shape
	 * @returns {Boolean}
	 */
	function _cutRequiredDiamond() {
		if (typeof veneer.cutImages[0] !== "undefined" && veneer.cutImages[0].width === veneer.cutImages[0].height) {
			return false;
		}

		activeTool = new CutDiamond(
			Math.max(Math.round(veneer.group.left), 0),
			Math.max(Math.round(veneer.group.top), 0),
			Math.min(Math.floor(veneer.group.width), Math.round(veneer.group.height), maxImageHeight)
		);
		return _startCutTool();
	}

	// unused
	function _cutRequiredSquare() {
		if (typeof veneer.cutImages[0] !== "undefined" && veneer.cutImages[0].width === veneer.cutImages[0].height) {
			return false;
		}

		activeTool = new CutStaticSquare(
			Math.max(Math.round(veneer.group.left), 0),
			Math.max(Math.round(veneer.group.top), 0),
			Math.min(Math.floor(veneer.group.width), Math.round(maxImageWidth / 2))
		);
		return _startCutTool();
	}

	function _cutRequiredVerticalTriangle() {
		if (typeof veneer.cutImages[0] !== "undefined") {
			return false;
		}

		activeTool = new CutStaticVerticalTriangle(
			Math.max(Math.round(veneer.group.left), 0),
			Math.max(Math.round(veneer.group.top), 0),
			Math.min(Math.floor(veneer.group.width), Math.round(veneer.group.height))
		);
		return _startCutTool();
	}

	function _cutRequiredHorizontalTriangle() {
		if (typeof veneer.cutImages[0] !== "undefined") {
			return false;
		}

		activeTool = new CutStaticHorizontalTriangle(
			Math.max(Math.round(veneer.group.left), 0),
			Math.max(Math.round(veneer.group.top), 0),
			Math.min(Math.floor(veneer.group.width), Math.round(maxImageWidth / 2))
		);
		return _startCutTool();
	}

	function _cutRequiredRightTriangle() {
		if (typeof veneer.cutImages[0] !== "undefined") {
			return false;
		}

		activeTool = new CutStaticRightTriangle(
			Math.max(Math.round(veneer.group.left), 0),
			Math.max(Math.round(veneer.group.top), 0),
			Math.min(Math.floor(veneer.group.width), Math.round(maxImageWidth / 2)),
			Math.round(veneer.group.height / 2)
		);
		return _startCutTool();
	}

	function prepareCut() {
		if (typeof veneer.cutImages[0] !== "undefined" && veneer.cutImages[0].width === veneer.cutImages[0].height) {
			return false;
		}
		updateImagedata();
		return true;
	}

	function cutRectangle() {
		if (prepareCut()) {
			activeTool = new CutRect(
				Math.max(Math.round(veneer.group.left), 0),
				Math.max(Math.round(veneer.group.top), 0),
				Math.min(veneer.group.width, veneer.group.height)
			);
			return _startCutTool();
		}
	}

	function cutCircle() {
		if (prepareCut()) {
			activeTool = new CutCircle(
				Math.max(Math.round(veneer.group.left + veneer.group.width / 2), 0),
				Math.max(Math.round(veneer.group.top + veneer.group.width / 2), 0),
				Math.min(veneer.group.width / 4, veneer.group.height / 4)
			);
			return _startCutTool();
		}
	}

	function cutTriangle() {
		if (prepareCut()) {
			activeTool = new CutTriangle(
				Math.max(Math.round(veneer.group.left + veneer.group.width / 2), 0),
				Math.max(Math.round(veneer.group.top + veneer.group.width / 2), 0),
				null,
				Math.min(veneer.group.width / 2, veneer.group.height)
			);
			return _startCutTool();
		}
	}

	function cutLasso() {
		if (prepareCut()) {
			/* LASSO sollte sich beim Klick auf Canvas aktivieren
			 activeTool = new LassoRound(
			 Math.max(Math.round(veneer.group.left), 0),
			 Math.max(Math.round(veneer.group.top), 0),
			 Math.min(veneer.group.width, veneer.group.height)
			 );
			 */
			return _startCutTool();
		}
	}

	function _startCutTool() {
		setToolBoundsToImageGroup(veneer.group);
		Renderer.getCanvas().add(activeTool);
		Renderer.getCanvas().deactivateAll();
		Renderer.getCanvas().renderAll();
		activeTool.staticStart();
		return true;
	}

	/**
	 * Cut using the currently active tool
	 * @param {type} event
	 * @returns {undefined}
	 */
	function cut(event) {
		veneer.clearCutImages();
		// get the new image
		var dataurl = activeTool.cut(),
			requireCut = getConfig().requireCut;

		if (requireCut === "diamond") {
			// rotate the diamond shape back to a square
			transformDiamondToSquare(dataurl);
		} else if (typeof requireCut !== "undefined") {
			// trim images of white space on sides
			trimImage(dataurl, addCutImageFromDataURL, this);
		} else {
			addCutImageFromDataURL(dataurl);
		}

		veneer.modifiers.multiplyX = 1;
		activeTool.destroy();
	}

	/**
	 * transforms a 45 deg rotated square back to a normal square
	 * makes it easier to use later since the bounding box will clip directly to the image again
	 * @param {type} dataurl
	 * @returns {undefined}
	 */
	function transformDiamondToSquare(dataurl) {
		var canvas = createTempCanvas(),
			ctx = Renderer.getCanvas().getContext();

		fabric.Image.fromURL(dataurl, function (cutimage) {
			var radians = fabric.util.degreesToRadians(45),
				factor = Math.sin(radians) + Math.cos(radians),
				// get image dimensions for after rotate
				width = Math.floor(cutimage.getWidth() / factor),
				height = Math.floor(cutimage.getHeight() / factor),
				cd = {width: width, height: height};

			// rotate back and set image position
			Renderer.getCanvas().setDimensions(cd);
			cutimage.setAngle(45);
			cutimage.setLeft(cutimage.getLeft() - Math.ceil((cutimage.getWidth() - width) / 2));
			cutimage.setTop(cutimage.getTop() - Math.ceil((cutimage.getHeight() - height) / 2));
			Renderer.getCanvas().add(cutimage);

			// move the image up until it clips to the canvas border
			var id = ctx.getImageData(0, 0, 1, 1);
			while (id.data[3] < trimTolerance) {
				cutimage.setTop(cutimage.getTop() - 1);
				cd.height--;
				cd.width--;
				Renderer.getCanvas().setDimensions(cd);
				Renderer.getCanvas().renderAll();
				id = ctx.getImageData(0, 0, 1, 1);
			}

			dataurl = Renderer.getCanvas().lowerCanvasEl.toDataURL();
			Renderer.getCanvas().dispose();
			canvas = null;
			addCutImageFromDataURL(dataurl);
		});
	}

	return {}
}());