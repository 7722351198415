/**
 * The controller module and entry point for the veneer designer app
 * @type {{init, imagesLoaded, closeDesigner}}
 */
var VeneerDesigner = (function () {

	// ImageData
	var ImageData,
		data,
		// whether to use a cut image to render
		useCutImage = false,
		// whether to use an extracted shape of a configured image
		useExtractedShape = false,
		// whether the designer is running in inline (non fullscreen) mode
		inlineMode = false;

	function init() {
		// initialize additional modules
		initializeModules();

		VeneerListModule.addVeneer(parseInt(document.getElementById('veneer_canvas').attributes.getNamedItem('data-uid').value));

		// bind event handlers
		InterfaceEvents.bindEventHandlers();
	}

	/**
	 * Certain modules need initialization to find dom elements or create other objects
	 * initialize these modules here
	 */
	function initializeModules() {
		Renderer.init();

		InterfaceEvents.init();
		VeneerListModule.init();
		TabsModule.init();
	}

	/**
	 * all original images have been loaded
	 * render the canvas
 	 */
	function imagesLoaded() {
		Renderer.render();
	}

	function reset() {
		useCutImage = false;
		useExtractedShape = false;
		//jointing = ImageStates.SINGLE;
		//veneer.clearCutImages();
	}

	function setToolBoundsToImageGroup(imagegroup) {
		activeTool.bounds = {
			left: Math.max(imagegroup.left, 0),
			top: Math.max(imagegroup.top, 0),
			right: Math.min(imagegroup.left + imagegroup.getWidth(), maxImageWidth),
			bottom: Math.min(imagegroup.top + imagegroup.getHeight(), maxImageHeight)
		};
	}

	function setDrag(drag) {
		for (var i = 0; i < veneers.length; i++) {
			veneers[i].group.selectable = drag;
		}
	}

	function hideCutTool() {
		if (typeof activeTool !== "undefined" && activeTool !== null) {
			activeTool.destroy();
		}
	}

	/**
	 * Exit the fullscreen mode
	 * @param ev
	 */
	function closeDesigner(ev) {
		ev.preventDefault();

		var container = document.getElementById('sg_tool_container');
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
		$(container).hide();
		VeneerGallery.closeGallery();
	}

	function fullscreenchange(e) {
		if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement) {
			closeDesigner(e);
		}
	}

	function setInlineMode(mode) {
		inlineMode = mode;

		if(mode) {
			$('#nav_close').hide();
		}
	}

	// listen for fullscreenchange events
	document.addEventListener('fullscreenchange', $.proxy(fullscreenchange, this));
	document.addEventListener('webkitfullscreenchange', $.proxy(fullscreenchange, this));
	document.addEventListener('mozfullscreenchange', $.proxy(fullscreenchange, this));
	document.addEventListener('msfullscreenchange', $.proxy(fullscreenchange, this));

	return {
		init: init,
		imagesLoaded: imagesLoaded,
		closeDesigner: closeDesigner,
		setInlineMode: setInlineMode
	}

}());

/*
 fabric.Canvas.prototype.imagedataToDataURL = function(imagedata) {
 var canvasEl = fabric.util.createCanvasElement();
 var ctx = canvasEl.getContext('2d');
 canvasEl.width = imagedata.width;
 canvasEl.height = imagedata.height;

 ctx.putImageData(imagedata, 0, 0);
 var dataurl = canvasEl.toDataURL();

 canvasEl = null;
 return dataurl;
 };
 */